import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { GetProfileApi } from '../../../http/Services/UserApi'
import { DateConvert } from '../../../utils/dateConvert'
import Avatar from '../../../assets/avatar.png'
import ModalChangeMyPass from './ModalChangeMyPass'
import { VerificationImageExist } from '../../../utils/VerificationImage'
export default function Profile() {
    const auth = useSelector(state => state.auth)
    const [user, setUser] = useState({})
    const [toggleModalPass, setToggleModalPass] = useState(false)


    useEffect(() => {
        if (auth.id !== 0) {
            const getUserProfile = async () => {
                try {
                    const res = await GetProfileApi()
                    setUser(res.data)

                    // const resPermissions = await GetUserPermissionsApi(auth.id)
                    // setPermissions([])
                } catch (error) {
                    console.log("error profile")
                }
            }
            getUserProfile()
        }
    }, [auth])

    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card card-danger card-outline">
                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        {/* <img className="profile-user-img img-fluid img-circle" src={Avatar} alt="img" style={{ width: '80px', height: '80px' }} /> */}
                                        <img src={user.avatar != null ? require(`../../../assets/${VerificationImageExist(user.avatar)}`) : Avatar} alt="img" style={{ width: '80px', height: '80px' }} />
                                    </div>
                                    <h3 className="profile-username text-center">{user.nombre || '---'}</h3>
                                    <p className="text-muted text-center">{user.departament && (user.departament.nombre === "Clientes" ? "Cliente" : user.departament.nombre)}</p>
                                    <ul className="list-group list-group-unbordered mb-3 p-1">
                                        {/* <li className="list-group-item">
                                            <b>Estado</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.estado || '--'}</span>
                                        </li> */}
                                        <li className="list-group-item">
                                            <ion-icon name="call"></ion-icon>
                                            <b> Teléfono</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.telefono || '--'}</span>
                                        </li>
                                        <li className="list-group-item">
                                            <ion-icon name="card"></ion-icon>
                                            <b> Código</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.email || '--'}</span>
                                        </li>
                                        <li className="list-group-item text-center">
                                            <div
                                                className="btn btn-danger"
                                                onClick={() => setToggleModalPass(!toggleModalPass)}
                                            >
                                                <i className="ion-locked" />
                                                {' '}
                                                <small
                                                    style={{
                                                        fontWeight: '600'
                                                    }}
                                                >
                                                    Cambiar contraseña
                                                </small>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {/* <div className="card card-danger">
                                <div className="card-header">
                                    <h3 className="card-title">Detalles</h3>
                                </div>
                                <div className="card-body">
                                    <strong><i className="fas fa-map-marker-alt mr-1" /> Dirección</strong>
                                    <p className="text-muted">{user.direccion || '--'}</p>
                                    <hr />
                                    <strong><ion-icon name="call"></ion-icon> Telefono</strong>
                                    <p className="text-muted">+591 {user.telefono || '--'}</p>
                                </div>
                            </div> */}
                        </div>
                        <div className="col-md-8">
                            <div className="card  card-danger card-outline">
                                {/* <div className="card-header">
                                    <h3 className="card-title">Detalles</h3>
                                </div> */}
                                <div className="card-body">
                                    <i className="ion-information-circled" />
                                    <small><strong> Nombre</strong></small>
                                    <p className="text-muted">{user.nombre || '--'}</p>
                                    <hr />
                                    <i className="ion-information-circled" />
                                    <small><strong> Apellido paterno</strong></small>
                                    <p className="text-muted"> {user.apellido_paterno || '--'}</p>
                                    <hr />
                                    <i className="ion-information-circled" />
                                    <small><strong> Apellido materno</strong></small>
                                    <p className="text-muted"> {user.apellido_materno || '--'}</p>
                                    <hr />
                                    {/* <ion-icon name="call"></ion-icon> */}
                                    <i className="fas fa-map-marker-alt mr-1" />
                                    <small><strong>Dirección</strong></small>
                                    <p className="text-muted">{user.direccion || '--'}</p>
                                    <hr />
                                    <i className="ion-calendar" />
                                    <small><strong> Fecha de registro</strong></small>
                                    <p className="text-muted">{user.created_at && (DateConvert(user.created_at) || '--')}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-9">
                            <div className="card">
                                <div className="card-header p-2">
                                    <ul className="nav nav-pills" >
                                        <li className="nav-item" ><a className="nav-link active bg-danger" href="#settings" data-toggle="tab">Mis datos</a></li>
                                        <li className="nav-item"><a className="nav-link" href="#roles" data-toggle="tab">{user.role.name}</a></li>
                                    </ul>
                                </div>
                                <div className="card-body">
                                    <div className="tab-content">
                                        <div className="tab-pane" id="roles">
                                            <Table hover size="sm" className='mb-0' responsive>
                                                <Thead
                                                    columns={["#", "Nombre", "Estado"]}
                                                />
                                                <tbody>
                                                    {
                                                        permissions.map((permission) => (
                                                            <tr key={permission.id}>
                                                                <td style={{ textAlign: 'right' }}>{num++}</td>
                                                                <td >{permission.nombre}</td>
                                                                <td>
                                                                    {
                                                                        permission.status ?
                                                                            <ButtonEmpty
                                                                                icon='bi bi-check'
                                                                                text='activo'
                                                                                color='success'
                                                                                disabled={true}
                                                                            />
                                                                            :
                                                                            <ButtonEmpty
                                                                                icon='bi bi-check'
                                                                                text='inactivo'
                                                                                color='error'
                                                                                disabled={true}
                                                                            />
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="tab-pane active" id="settings">
                                            <form className="form-horizontal">
                                                <label htmlFor="inputName" className="col-sm-12 col-form-label">Nombre</label>
                                                <div className="col-sm-12">
                                                    <input type="email" className="form-control" id="inputName" placeholder="Name" defaultValue={user.nombre} disabled />
                                                </div>
                                                <label htmlFor="inputName2" className="col-sm-12 col-form-label">Apellido Paterno</label>
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" defaultValue={user.apellido_paterno} disabled />
                                                </div>
                                                <label htmlFor="inputName2" className="col-sm-12 col-form-label">Apellido materno</label>
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" defaultValue={user.apellido_materno} disabled />
                                                </div>
                                                <label htmlFor="inputEmail" className="col-sm-12 col-form-label">Correo</label>
                                                <div className="col-sm-12">
                                                    <input type="email" className="form-control" id="inputEmail" placeholder="Email" defaultValue={user.email} disabled />
                                                </div>
                                                <label htmlFor="inputName2" className="col-sm-12 col-form-label">Carnet</label>
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" defaultValue={user.carnet} disabled />
                                                </div>
                                                <label htmlFor="inputExperience" className="col-sm-12 col-form-label">Telefono</label>
                                                <div className="col-sm-12">
                                                    <textarea className="form-control" id="inputExperience" placeholder="Experience" defaultValue={user.telefono} />
                                                    <input type='text' className="form-control" id="inputExperience" placeholder="Experience" defaultValue={user.telefono} disabled />
                                                </div>
                                                <label htmlFor="inputSkills" className="col-sm-12 col-form-label">Ubicacion</label>
                                                <div className="col-sm-12">
                                                    <input type="text" className="form-control" id="inputSkills" placeholder="Skills" defaultValue={user.direccion} disabled />
                                                </div>
                                                <div className="form-group row">
                                                    <div className="offset-sm-2 col-sm-10">
                                                        <div className="checkbox">
                                                            <label>
                                                                <input type="checkbox" /> I agree to the
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group row">
                                                    <div className="offset-sm-2 col-sm-10">
                                                        <div className="col-sm-12">
                                                            <button type="button" className="btn btn-danger mt-2">Guardar cambios</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
            {
                toggleModalPass &&
                <ModalChangeMyPass
                    show={toggleModalPass}
                    setToggleModalPass={setToggleModalPass}
                //   recallAction={recallAction}
                //   seller={seller}
                />
            }
        </div>
    )
}
