import { GET_DATA, LOGIN, LOGOUT, NOTE_ASSIGNED, SET_DEP } from "../type";

export const initialState = {
    id: 0,
    nombre: "",
    apellido_paterno: "",
    apellido_materno: "",
    departament: {},
    noteId: null
}

export function authReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            // localStorage.setItem('token', action.payload.token)
            sessionStorage.setItem('token', action.payload.token)
            return state
        case GET_DATA:
            return state = action.payload.data
        case SET_DEP:
            let val
            if (action.payload === "Administración") {
                val = "adm"
            }
            else if (action.payload === "Ventas") {
                val = "vnt"
            }
            else if (action.payload === "Clientes") {
                val = "cnt"
            }
            else if (action.payload === "Punto de Venta") {
                val = "pvnt"
            }
            // window.localStorage.setItem("dp", val)
            window.sessionStorage.setItem("dp", val)
            return state
        case LOGOUT:
            // localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            // localStorage.removeItem('dp')
            sessionStorage.removeItem('dp')
            state = initialState
            return state
        case NOTE_ASSIGNED:
            return {
                ...state,
                noteId: action.payload
            }
        default:
            return state;
    }
}