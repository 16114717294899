import React, { useEffect, useState } from 'react'
import { VerificationImageExist } from '../../../utils/VerificationImage'
import Avatar from '../../../assets/profile.png'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { ThParam } from '../../globalParams/ThParam'
import { Tr } from '../../../components/table/Tr'
import { Table } from '../../../components/table/Table'
import { Td } from '../../../components/table/Td'
import { onChangeInputData, order, orderNum, recall } from '../../globalParams/GlobalDataFilter'
import Paginator from '../../globalParams/filter/Paginator'
import Searcher from '../../globalParams/filter/Searcher'
import Info from '../../globalParams/filter/Info'
import Loading from '../../globalParams/Loading'
import Detail from '../../globalParams/Detail'
import { ChangeClientStatusApi, DeleteClientsApi, GetClientsApi } from '../../../http/Services/ClientApi'
import ButtonTextIcon from '../../../components/button/ButtonTextIcon'
import ModalCreateClient from './ModalCreateClient'
import ModalConfirmation from '../../../components/modal/ModalConfirmation'
import { useDispatch, useSelector } from 'react-redux'
import { toastToggleAction } from '../../../redux/action/toastAction'
import ButtonIcon from '../../../components/button/ButtonIcon'
import { DateConvert } from '../../../utils/dateConvert'
import ModalEditClient from './ModalEditClient'
import ModalUpdateGeoClient from './ModalUpdateGeoClient'
import ClientImageModalCreate from './ClientImageModalCreate'
import ModalChangePass from './ModalChangePass'
import { GetGlobalDataApi } from '../../../http/Services/UserApi'
import { getGlobalDataAction } from '../../../redux/action/globalDataAction'
import { useNavigate } from 'react-router-dom'

export default function Clients() {

    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)
    const navigate = useNavigate()

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)
    const [toggleModalCreate, setToggleModalCreate] = useState(false)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [toggleModalEdit, setToggleModalEdit] = useState(false)
    const [openGeo, setOpenGeo] = useState(false)
    const [open, setOpen] = useState(false)
    const [toggleModalPass, setToggleModalPass] = useState(false)
    const [status, setStatus] = useState(true)

    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getSellers = async () => {
            try {
                let res = await GetClientsApi();
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getSellers()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    const getGlobalData = async () => {
        try {
            let res = await GetGlobalDataApi();
            if (res.status === 200) {
                dispatch(getGlobalDataAction(res.data))
            }
        } catch (error) {
            console.log('error in reducers')
        }
    }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }

    const deleteData = async () => {
        try {
            setButtonDisabled(true)
            let res = await DeleteClientsApi(item.id)
            if (res.status === 200) {
                handleCloseConfirmation()
                recall(setLoad, load)
                setButtonDisabled(false)
                getGlobalData()

                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Eliminado correctamente!',
                    variant: 'bold'
                }))
            }
        } catch (error) {
            setButtonDisabled(false)
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: error.response && error.response.data.message ? error.response.data.message : 'No se puede eliminar el cliente!',
                variant: 'bold'
            }))
        }
    }

    const changeStatus = async (id, status) => {
        // console.log(id)
        setStatus(false)
        try {
            let res = await ChangeClientStatusApi(id, status);
            if (res.status === 200) {
                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Estado modificado correctamente!',
                    variant: 'bold'
                }))
                setStatus(true)
                getGlobalData()
                items.forEach(element => {
                    if (element.id === id) {
                        element.estado = status
                        element.codigo = res.data.codigo
                    }
                });
            }
        } catch (error) {
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: error.response.data.message ? error.response.data.message : 'No se puede eliminar el cliente!',
                variant: 'bold'
            }))
            setStatus(true)
        }
    }

    const handleShow = () => {
        setToggleModalCreate(true)
    }
    const handleClose = () => {
        setToggleModalCreate(false)
    }
    const handleCloseConfirmation = () => setModalConfirmation(false)
    const handleShowConfirmation = () => {
        setModalConfirmation(true);
    }
    const handleCloseEdit = () => {
        setToggleModalEdit(false)
    }
    const handleCloseGeoEdit = () => {
        setOpenGeo(false)
    }
    const openModalGeolocalizationUpdate = () => {
        setOpenGeo(!openGeo)
    }
    const openModalImageCreate = () => {
        setOpen(!open)
    }
    const seeMore = (id) => {
        navigate("" + id)
    }

    return (
        <GeneralContainer>
            <div className="row gx-3">
                <div className="col-12 mb-3">
                    <div className='row gx-1 p-1 d-flex justify-content-end'>
                        <div className="col-6 col-sm-3 col-md-2">
                            <ButtonTextIcon
                                icon={<i className="ion-person-add"></i>}
                                variant="danger"
                                title="Registrar"
                                onClick={() => handleShow()}
                            />
                        </div>
                    </div>
                </div>
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    {/* <ThParam
                                    label='Id'
                                    onClick={() => orderNum('id')}
                                /> */}
                                                    <ThParam
                                                        label='Código'
                                                        onClick={() => order('codigo', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    />
                                                    <ThParam
                                                        label='Nombre'
                                                        onClick={() => order('nombre', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Celular'
                                                        onClick={() => order('telefono', data, desc, setData, setDesc)}
                                                        width='100px'
                                                    />
                                                    <ThParam
                                                        label='Encargado'
                                                        onClick={() => order('u_nombre', data, desc, setData, setDesc)}
                                                        width='130px'
                                                    />
                                                    <ThParam
                                                        label='Estado'
                                                        onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='65px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                        width='170px'
                                                    />
                                                    {
                                                        user && (user.departament.nombre === "Administración" || user.departament.nombre === "Ventas" || user.departament.nombre === "Punto de Venta") &&
                                                        <ThParam
                                                            label='Acciones'
                                                            // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                            width='120px'
                                                        />
                                                    }
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            >
                                                                <Td
                                                                    width='120px'
                                                                >
                                                                    <div className="row g-0">
                                                                        <div className="col-12 d-flex"
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                overflow: 'hidden'
                                                                            }}
                                                                        >
                                                                            <img
                                                                                className="p-0"
                                                                                src={data && data.avatar
                                                                                    ?
                                                                                    require(`../../../assets/${VerificationImageExist(data.avatar)}`) : Avatar}
                                                                                alt="img"
                                                                                style={{
                                                                                    width: '30px',
                                                                                    height: '30px',
                                                                                    alignItems: 'center',
                                                                                    textAlign: 'center',
                                                                                    verticalAlign: 'middle'
                                                                                }}
                                                                            />
                                                                            <span className='pl-1'>
                                                                                {data.codigo ? data.codigo : '---- ----'}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.nombre}{' '}
                                                                    {data.apellido_paterno}{' '}
                                                                    {data.apellido_materno}
                                                                </Td>
                                                                <Td
                                                                    width='100px'
                                                                >
                                                                    {data.telefono}
                                                                </Td>
                                                                <Td
                                                                    width='130px'
                                                                >
                                                                    {data.u_nombre}{' '}
                                                                    {data.u_apellido_paterno}{' '}
                                                                    {data.u_apellido_materno}
                                                                </Td>
                                                                <Td
                                                                    width='65px'
                                                                >
                                                                    {
                                                                        user && user.departament.nombre === "Administración" ?
                                                                            <button
                                                                                data-tip data-for="registerTip"
                                                                                // onMouseOver={() => setMessageTooltip('doble click para cambiar estado')}
                                                                                className={`btn btn-link text-decoration-none p-0 text-${data.estado ? (data.estado === 'Nuevo' ? 'danger' : (data.estado === 'Activo' ? 'success' : 'secondary')) : 'secondary'}`} onDoubleClick={() => changeStatus(data.id, (data.estado === 'Inactivo' ? 'Activo' : (data.estado === 'Nuevo' ? 'Activo' : 'Inactivo')))} disabled={status ? false : true} ><small><b><i>{data.estado}</i></b></small></button>
                                                                            :
                                                                            <div
                                                                                className='btn btn-link text-decoration-none p-0'
                                                                            >
                                                                                <small><b><i className={`text-${data.estado ? (data.estado === 'Nuevo' ? 'danger' : (data.estado === 'Activo' ? 'success' : 'secondary')) : 'secondary'}`}>{data.estado}</i></b></small>
                                                                            </div>
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='170px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                                <Td
                                                                    width='120px'
                                                                    center={true}
                                                                >
                                                                    <ButtonIcon
                                                                        icon={"bi bi-eye-fill"}
                                                                        variant="info"
                                                                        title="Ver más"
                                                                        onClick={() => seeMore(data.id)}
                                                                        color='text-white'
                                                                    />{' '}
                                                                    {
                                                                        user && user.departament.nombre !== "Administración" && data.estado !== "Activo" &&
                                                                        <>
                                                                            <ButtonIcon
                                                                                icon={"bi bi-geo-alt-fill"}
                                                                                variant="primary"
                                                                                title="Ver más"
                                                                                color='text-white'
                                                                                onClick={() => openModalGeolocalizationUpdate(true)}
                                                                                disabled={user && (user.departament.nombre === "Administración" || data.estado !== "Activo") ? false : true}
                                                                            />{' '}
                                                                            <ButtonIcon
                                                                                icon={"bi bi-image-fill"}
                                                                                variant="success"
                                                                                title="Ver más"
                                                                                color='text-white'
                                                                                onClick={() => openModalImageCreate()}
                                                                                disabled={user && (user.departament.nombre === "Administración" || data.estado !== "Activo") ? false : true}
                                                                            />{' '}
                                                                        </>
                                                                    }
                                                                    {
                                                                        user && user.departament.nombre === "Administración" &&
                                                                        <>
                                                                            <ButtonIcon
                                                                                icon={"bi bi-pencil-square"}
                                                                                variant="warning"
                                                                                title="Editar"
                                                                                onClick={() => setToggleModalEdit(true)}
                                                                            />{' '}
                                                                            <ButtonIcon
                                                                                icon={"bi bi-trash-fill"}
                                                                                variant="danger"
                                                                                title="eliminar"
                                                                                onClick={() => handleShowConfirmation(data.id)}
                                                                            />
                                                                        </>
                                                                    }

                                                                </Td>
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody >
                                        </Table >
                                    </div>
                                </div>
                                {
                                    total > perPage && stateData !== "loading..." &&
                                    <>
                                        <div className=" p-1 table-warning rounded-top">
                                            <Info
                                                total={total}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                        <div className="p-1">
                                            <Paginator
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                setPerPage={setPerPage}
                                                perPage={perPage}
                                                setLoad={setLoad}
                                                load={load}
                                                stateData={stateData}
                                                setFull={setFull}
                                                full={full}
                                                hiddenpaginator={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles del cliente
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <div className="d-flex justify-content-end p-2">

                                    <button
                                        className='bi bi-eye-fill btn btn-info text-white ml-2 elevation-2'
                                        onClick={() => seeMore(item.id)}
                                    // disabled={user && (user.departament.nombre === "Administración" || item.estado !== "Activo") ? false : true}
                                    />
                                    <button
                                        className='bi bi-geo-alt-fill btn btn-primary ml-2 elevation-2'
                                        onClick={() => openModalGeolocalizationUpdate(true)}
                                        disabled={user && (user.departament.nombre === "Administración" || item.estado !== "Activo") ? false : true}
                                    />
                                    <button
                                        className='bi bi-image-fill btn-success btn ml-2 elevation-2'
                                        onClick={() => openModalImageCreate()}
                                        disabled={user && (user.departament.nombre === "Administración" || item.estado !== "Activo") ? false : true}
                                    />
                                    {
                                        user && user.departament.nombre === "Administración" &&
                                        <i
                                            className='bi bi-pencil-square btn btn-warning ml-2 elevation-2'
                                            onClick={() => setToggleModalEdit(true)}
                                        />
                                    }
                                    {
                                        user && user.departament.nombre === "Administración" &&
                                        <i
                                            className='bi bi-lock-fill btn btn-secondary ml-2 elevation-2'
                                            onClick={() => setToggleModalPass(true)}
                                        />
                                    }
                                    {
                                        user && user.departament.nombre === "Administración" &&
                                        <i
                                            className='bi bi-trash-fill btn btn-danger ml-2 elevation-2'
                                            onClick={() => handleShowConfirmation(data.id)}
                                        />
                                    }
                                </div>
                                <Detail
                                    detail={[
                                        { label: 'Codigo', value: item.codigo },
                                        { label: 'Nombre', value: item.nombre },
                                        { label: 'Apellido paterno', value: item.apellido_paterno },
                                        { label: 'Apellido materno', value: item.apellido_materno },
                                        { label: 'Carnet', value: item.carnet },
                                        { label: 'Telefono', value: item.telefono },
                                        { label: 'Zona', value: item.zona ? item.zona : '' },
                                        { label: 'Estado', value: item.estado ? item.estado : '', color: item.estado === 'Activo' ? 'table-success' : (item.estado === 'Nuevo' ? 'table-danger' : 'table-secondary') },
                                        { label: 'Fecha de registro', value: item.created_at ? DateConvert(item.created_at) : '' },
                                        { label: 'Tiempo consumo por turril', value: item.tiempo_consumo_turril ? item.tiempo_consumo_turril : '' },
                                        { label: 'Geolocalización', value: item.geolocalizacion ? item.geolocalizacion : '' },
                                        { label: 'Vendedor encargado', value: item.u_nombre + ' ' + (item.u_apellido_paterno ? item.u_apellido_paterno : '') + ' ' + (item.u_apellido_materno ? item.u_apellido_materno : '') },
                                        { label: 'Departamento vendedor', value: item.u_dpto },
                                        { label: 'Tipo de negocio', value: item.tipo_negocio ? item.tipo_negocio : '' },
                                        { label: 'Foto del negocio', value: item.foto_negocio ? item.foto_negocio : '', img: true },
                                    ]}
                                // id={item.id}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                toggleModalCreate &&
                <ModalCreateClient
                    show={toggleModalCreate}
                    handleClose={handleClose}
                    load={load}
                    setLoad={setLoad}
                />
            }
            {
                modalConfirmation &&
                <ModalConfirmation
                    show={modalConfirmation}
                    handleClose={handleCloseConfirmation}
                    text='¿Seguro que desea eliminar este cliente?'
                    buttonText='Eliminar'
                    variant='danger'
                    onClick={() => deleteData()}
                    buttonDisabled={buttonDisabled}
                />
            }
            {
                toggleModalEdit &&
                <ModalEditClient
                    show={toggleModalEdit}
                    handleClose={handleCloseEdit}
                    load={load}
                    setLoad={setLoad}
                    client={item}
                />
            }
            {
                openGeo &&
                <ModalUpdateGeoClient
                    show={openGeo}
                    handleClose={handleCloseGeoEdit}
                    load={load}
                    setLoad={setLoad}
                    client={item}
                />
            }
            {open &&
                <ClientImageModalCreate
                    id={item.id}
                    open={open}
                    openModalImageCreate={openModalImageCreate}
                    load={load}
                    setLoad={setLoad}
                />
            }
            {toggleModalPass &&
                <ModalChangePass
                    show={toggleModalPass}
                    setToggleModalPass={setToggleModalPass}
                    load={load}
                    setLoad={setLoad}
                    client={item}
                />
            }
        </GeneralContainer>
    )
}

// 001-001

// adicionar campo codigo, numero_referencia en la tabla cliente


// boton ir arriba