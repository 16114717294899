import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import ButtonLinkIcon from '../../../../components/button/ButtonLinkIcon'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import SelecterDataButton from '../../../../components/form/SelecterDataButton'
import Label from '../../../../components/label/Label'
import InputTextTarea from '../../../../components/textField/InputTextTarea'
import { GetMyClientsWithOpenAccountApi } from '../../../../http/Services/Account'
import { createHistoryAccountApi } from '../../../../http/Services/HistoryAccountApi'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import { DateConvert } from '../../../../utils/dateConvert'

export default function ModalCreatePayment({ show, handleClose, recallAction, cuadernoId }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [clientAccount, setClientAccount] = useState(null)

    const [userOptions, setUserOptions] = useState([])
    const [clients, setClients] = useState(null)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const getClients = async () => {
            try {
                let res = await GetMyClientsWithOpenAccountApi();
                let resList = []
                if (res.status === 200) {
                    setClients(res.data)
                    res.data.map(data =>
                        resList.push({ value: data.id, label: (data.saldo + " Bs., " + (data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '')) + ', apertura: ' + (DateConvert(data.created_at, 'L'))  + ', límite: ' + (DateConvert(data.fecha_limite, 'L'))) })
                    )
                    setUserOptions(resList)
                }
            } catch (error) {

            }
        }
        getClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange2 = (e) => {
        console.log(e)
        setUser(e)
        if (e !== null) {
            setClientAccount(clients.find(cli => cli.id === e.value))
        }
        else {
            setClientAccount(null)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar cobro</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        cuenta_id: 0,
                        cuaderno_id: cuadernoId,
                        metodo_pago: 'Efectivo',
                        monto: 0,
                        obs: ''
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)
                        const CreatePayment = async () => {
                            try {
                                valores.cuenta_id = clientAccount.id;

                                let res = await createHistoryAccountApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    resetForm()
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar pago!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                // setButtonDisabled(false)
                            }
                        }

                        CreatePayment();
                    }}
                >
                    {({ values, handleChange }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row">
                                        <div className="col-md-8 mb-2">
                                            <Label
                                                title='Cliente'
                                            >
                                                <ReactSelect
                                                    maxMenuHeight={250}
                                                    options={
                                                        userOptions
                                                    }
                                                    name="user_id"
                                                    value={user}
                                                    onChange={handleChange2}
                                                    isDisabled={buttonDisabled}
                                                    required={true}
                                                    isClearable={true}
                                                    className='fw-bold'
                                                />
                                            </Label>
                                            <div>
                                                {
                                                    user !== null &&
                                                    <SelecterDataButton
                                                        data={user.label} />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-4 mb-2">
                                            <Label
                                                title='Monto'
                                            >
                                                <InputBeforeIcon
                                                    id='monto'
                                                    placeholder="Cobrado"
                                                    type='number'
                                                    onChange={handleChange}
                                                    name='monto'
                                                    value={values.monto}
                                                    disabled={buttonDisabled}
                                                    param='Bs.'
                                                    min={1}
                                                    max={clientAccount ? clientAccount.saldo : 1}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 col-md-6 mb-2">
                                            <Label
                                                title='Método de pago'
                                            >
                                                <select
                                                    className="form-select"
                                                    name="metodo_pago"
                                                    value={values.metodo_pago}
                                                    onChange={handleChange}
                                                    style={{ boxShadow: 'none' }}
                                                    disabled={buttonDisabled}
                                                >
                                                    <option value='Efectivo' label={'Efectivo'} />
                                                    <option value='Transferencia' label={'Transferencia'} />
                                                </select>
                                            </Label>
                                        </div>
                                        <div className=" col-12 mt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-lg-6 row d-flex justify-content-center">
                                                    <div className="row table-warning py-1">
                                                        <div className="col-5"><small>Saldo</small></div>
                                                        <div className="col-5  text-danger overflow-hidden">
                                                            <strong>{clientAccount ? clientAccount.saldo : 0}</strong>
                                                        </div>
                                                        <div className="col-2"><small>Bs.</small></div>
                                                    </div>
                                                    <div className="row table-info py-1">
                                                        <div className="col-5"><small>Monto a cancelar</small></div>
                                                        <div className="col-5  text-primary overflow-hidden">
                                                            <strong>{values.monto}</strong>
                                                        </div>
                                                        <div className="col-2"><small>Bs.</small></div>
                                                    </div>
                                                    <div className="row table-success py-1">
                                                        <div className="col-5"><small>Saldo restante</small></div>
                                                        <div className="col-5  text-dark overflow-hidden">
                                                            <strong>{clientAccount ? clientAccount.saldo - values.monto : 0}</strong>
                                                        </div>
                                                        <div className="col-2"><small>Bs.</small></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <Label
                                                title='Observaciones'
                                            >
                                                <InputTextTarea
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Observaciones...'
                                                    value={values.obs}
                                                    name='obs'
                                                    disabled={buttonDisabled}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <ButtonLinkIcon
                                                title={!clientAccount && 'Debe seleccionar un cliente!'}
                                                iconB={!clientAccount && <i className="bi bi-exclamation-triangle-fill" />}
                                                bg={!clientAccount && 'warning'}
                                                // color={!client && 'secondary'}
                                                // bold={false}
                                                btn={false}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Registrar cobro'
                                        color='#00bfa5'
                                        // disabled={values.monto > 0 && clientAccount ? false : true}
                                        disabled={clientAccount ? buttonDisabled : true}
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
