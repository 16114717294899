import { Formik, Form } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import { CreateClientApi } from '../../../http/Services/ClientApi'
import { toastToggleAction } from '../../../redux/action/toastAction'
import InputTextTarea from '../../../components/textField/InputTextTarea'
import Label from '../../../components/label/Label'
import InputBasic from '../../../components/form/InputBasic'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { VenueLocationIcon } from '../../../components/maps/VenueIconLocation'
import { recall } from '../../globalParams/GlobalDataFilter'
import ReactSelect from 'react-select'
import { GetAllSellersApi } from '../../../http/Services/UserApi'
import config from '../../../config.json'

export default function ModalCreateClient({
    show,
    handleClose,
    // recallAction
    load,
    setLoad
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [userOptions, setUserOptions] = useState([])
    const [user, setUser] = useState(null)

    const dispatch = useDispatch()
    const userData = useSelector(state => state.auth)

    const center = {
        lat: config.lat,
        lng: config.long,
    }
    const centerDefault = ''

    const [position, setPosition] = useState(center)
    const [location, setLocation] = useState(centerDefault)

    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    setLocation(marker._latlng.lat + ',' + marker._latlng.lng)
                }
            },
        }),
        [],
    )

    const currentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLocation(position.coords.latitude + "," + position.coords.longitude)
            setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        });
    }

    const handleChangeOne = (e) => {
        setLocation(e.target.value)
    }

    useEffect(() => {
        // console.log(show)
        const getAllSellers = async () => {
            try {
                let res = await GetAllSellersApi();
                let resList = []
                if (res.status === 200) {
                    res.data.map(data =>
                        resList.push({ value: data.id, label: (data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '') + ', ' + (data.dpto ? (data.dpto === 'Ventas' ? 'Vendedor' : data.dpto) : '')) })
                    )
                    setUserOptions(resList)
                }
            } catch (error) {

            }
        }
        setUser(null)
        if (show) {
            getAllSellers()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const handleChange2 = (e) => {
        setUser(e)
    }

    return (
        <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>Registro de Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    nombre: '',
                    apellido_paterno: '',
                    apellido_materno: '',
                    telefono: '',
                    zona: '',
                    carnet: '0',
                    tiempo_consumo_turril: 0,
                    tipo_negocio: 'Abarrotes',
                    geolocalizacion: '',
                    geocurrent: '',
                    obs: '',
                    avatarImg: '',
                    user_id: null
                }}

                onSubmit={(valores, { resetForm }) => {
                    setButtonDisabled(true)

                    const createClient = async () => {
                        try {
                            valores.geolocalizacion = location
                            valores.telefono = valores.telefono + ""
                            if (user != null) {
                                valores.user_id = user.value
                            }
                            let res = await CreateClientApi(valores)
                            if (res.status === 200) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'success',
                                    title: 'Exito!',
                                    message: 'Creado correctamente!',
                                    variant: 'bold'
                                }))

                                resetForm()
                                recall(setLoad, load)
                                handleClose()
                                setButtonDisabled(false)
                            }
                            else {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Alerta!',
                                    message: 'Los datos podrian no haberse cargado!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        } catch (error) {
                            console.log(error)
                            dispatch(toastToggleAction({
                                show: true,
                                bg: 'danger',
                                title: 'Error!',
                                message: error.response.data.message,
                                variant: 'bold'
                            }))
                            setButtonDisabled(false)
                        }
                    }

                    createClient()
                }}
            >
                {({ values, handleChange }) => (
                    <React.Fragment>
                        <Form>
                            <Modal.Body>
                                <div className="row">
                                    {
                                        userData && userData.departament.nombre === "Administración" &&
                                        <div className="col-sm-6 mb-2">
                                            <Label
                                                title='Vendedores y Puntos de venta'
                                            >
                                                <ReactSelect
                                                    maxMenuHeight={200}
                                                    options={
                                                        userOptions
                                                    }
                                                    name="user_id"
                                                    value={user}
                                                    onChange={handleChange2}
                                                    isDisabled={buttonDisabled}
                                                    required={false}
                                                    isClearable={true}
                                                    className='fw-bold'
                                                />
                                            </Label>
                                        </div>
                                    }
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Nombre'
                                        >
                                            <InputBasic
                                                id='nombre'
                                                placeholder='Nombre'
                                                name='nombre'
                                                type='text'
                                                onChange={handleChange}
                                                value={values.nombre}
                                                disabled={buttonDisabled}
                                                required={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">

                                        <Label
                                            title='Apellido paterno'
                                        >
                                            <InputBasic
                                                id='apellido_paterno'
                                                placeholder='Apellido paterno'
                                                name='apellido_paterno'
                                                type='text'
                                                onChange={handleChange}
                                                value={values.apellido_paterno}
                                                disabled={buttonDisabled}
                                            // required={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Apellido materno'
                                        >
                                            <InputBasic
                                                id='apellido_materno'
                                                placeholder='Apellido materno'
                                                name='apellido_materno'
                                                type='text'
                                                onChange={handleChange}
                                                value={values.apellido_materno}
                                                disabled={buttonDisabled}
                                            // required={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Teléfono'
                                        >
                                            <InputBasic
                                                id='telefono'
                                                placeholder='Teléfono'
                                                name='telefono'
                                                type='number'
                                                onChange={handleChange}
                                                value={values.telefono}
                                                disabled={buttonDisabled}
                                                // required={false}
                                                // pattern="[0-9]{8,8}"
                                                // minLength="8"
                                                // maxLength="8"
                                                min="2000000"
                                                max="79999999"
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Zona'
                                        >
                                            <InputBasic
                                                id='zona'
                                                placeholder='Zona'
                                                name='zona'
                                                type='text'
                                                onChange={handleChange}
                                                value={values.zona}
                                                disabled={buttonDisabled}
                                                required={true}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2 mb-2">
                                        <Label
                                            title='Tipo de negocio'
                                        >
                                            <select
                                                className="form-select"
                                                name="tipo_negocio"
                                                value={values.tipo_negocio}
                                                onChange={handleChange}
                                                style={{ boxShadow: 'none' }}
                                                disabled={buttonDisabled}
                                            >
                                                <option value="Abarrotes">Abarrotes</option>
                                                <option value="Restaurante">Restaurante</option>
                                                <option value="Carniceria">Carniceria</option>
                                                <option value="Tienda">Tienda</option>
                                                <option value="Puesto movil">Puesto Movil</option>
                                                <option value="Kiosco">Kiosco</option>
                                                <option value="Feria">Feria</option>
                                                <option value="Empresa">Empresa</option>
                                                <option value="Mayorista">Mayorista</option>
                                                <option value="Fabrica">Fábrica</option>
                                                <option value="Provincia">Provincia</option>
                                            </select>
                                        </Label>
                                    </div>
                                    <div className="col-12 my-2 p-1 text-center">
                                        <small><strong>Información adicional</strong> <span style={{ color: '#464343' }}>(opcional)</span></small>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Carnet de identidad'
                                        >
                                            <InputBasic
                                                id='carnet'
                                                placeholder='ej. 123456LP'
                                                name='carnet'
                                                type='text'
                                                onChange={handleChange}
                                                value={values.carnet}
                                                disabled={buttonDisabled}
                                            // pattern='[0-9]{5,12}\s[a-z]{1,2}$'
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Tiempo de consumo de turril(en días)'
                                        >
                                            <InputBasic
                                                id='tiempo_consumo_turril'
                                                placeholder=''
                                                name='tiempo_consumo_turril'
                                                type='number'
                                                onChange={handleChange}
                                                value={values.tiempo_consumo_turril}
                                                disabled={buttonDisabled}
                                            // pattern='[0-9]{5,12}\s[a-z]{1,2}$'
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <Label
                                            title='Observaciones'
                                        >
                                            <InputTextTarea
                                                rows={1}
                                                onChange={handleChange}
                                                placeholder='Observaciones...'
                                                value={values.obs}
                                                name='obs'
                                                disabled={buttonDisabled}
                                            />
                                        </Label>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-9">
                                                <Label
                                                    title='Geolocalización'
                                                >
                                                    <input
                                                        id='geolocalizacion'
                                                        type="text"
                                                        className={`form-control ${location && location !== "" && 'bg-primary border border-primary'}`}
                                                        placeholder="Geolocalización"
                                                        onChange={handleChangeOne}
                                                        value={location}
                                                        disabled={buttonDisabled}
                                                    />
                                                </Label>
                                            </div>
                                            <div className="col-3 mt-4 text-right">
                                                <span className="input-group-text btn btn-primary" type='button' onClick={() => currentLocation()}><i className='bi bi-geo-alt-fill' /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='p-0 text-dark' >Editar ubicación manualmente</Accordion.Header>
                                                <Accordion.Body className='p-1 border border-secondary border-3'>
                                                    <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                                                        <TileLayer
                                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <Marker
                                                            draggable={true}
                                                            eventHandlers={eventHandlers}
                                                            position={position}
                                                            ref={markerRef}
                                                            icon={VenueLocationIcon}
                                                        >
                                                            <Popup minWidth={60} >
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px'
                                                                    }}
                                                                >
                                                                    {location}
                                                                </span>
                                                            </Popup>
                                                        </Marker>
                                                    </MapContainer>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <ButtonSubmitSave
                                    text='Guardar'
                                    color='#00bfa5'
                                    disabled={buttonDisabled}
                                />
                            </Modal.Footer>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Modal >
    )
}
