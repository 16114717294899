import axios from "axios";

export async function CreateHistoryBarrelAccountApi(data) {
    const response = axios({
        url: `/historyBarrelAccount`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}
