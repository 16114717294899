import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
// import ButtonIcon from '../../../components/button/ButtonIcon'
import ButtonTextIcon from '../../../components/button/ButtonTextIcon'
import ModalConfirmation from '../../../components/modal/ModalConfirmation'
import { Table } from '../../../components/table/Table'
import { Td } from '../../../components/table/Td'
import { Tr } from '../../../components/table/Tr'
import { DeleteHistoryWareHouseByIdApi } from '../../../http/Services/HistoryWarehouseApi'
import { GetHistoryWareHouseByIdApi, GetWareHouseByIdApi } from '../../../http/Services/WareHouseApi'
import { toastToggleAction } from '../../../redux/action/toastAction'
import { DateConvert } from '../../../utils/dateConvert'
import Detail from '../../globalParams/Detail'
import Info from '../../globalParams/filter/Info'
import Paginator from '../../globalParams/filter/Paginator'
import Searcher from '../../globalParams/filter/Searcher'
import GeneralContainer from '../../globalParams/GeneralContainer'
import { onChangeInputData, order, orderNum } from '../../globalParams/GlobalDataFilter'
import Loading from '../../globalParams/Loading'
import { ThParam } from '../../globalParams/ThParam'
// import ModalCreateHistorialWareHouse from './ModalCreateHistorialWareHouse'
// import ModalCreateHistorialWareHouseHome from './ModalCreateHistorialWareHouseHome'
import ModalCreateHistorialWareHouseSale from './ModalCreateHistorialWareHouseSale'
import ModalCreateHistorialWareHouseInput from './ModalCreateHistorialWareHouseInput'
import ModalAlmacenIngreso from './ModalAlmacenIngreso'
import ModalAlmacenEgreso from './ModalAlmacenEgreso'


export default function HistoryWareHouse() {

    const dispatch = useDispatch()
    const param = useParams()

    const [stateData, setStateData] = useState("loading...")
    const [load, setLoad] = useState(false)
    const [data, setData] = useState([])
    const [headerData, setHeaderData] = useState({})
    const [back, setBack] = useState([])
    const [items, setItems] = useState([])
    const [item, setItem] = useState({})
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [total, setTotal] = useState(0)
    const [desc, setDesc] = useState(true)
    const [byQuery, setByQuery] = useState("")
    const [full, setFull] = useState(true)
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [openModalCreateWareHouse, setOpenModalCreateWareHouse] = useState(false)
    const [openModalCreateWareHouseHome, setOpenModalCreateWareHouseHome] = useState(false)
    const [openModalCreateWareHouseSale, setOpenModalCreateWareHouseSale] = useState(false)
    const [openModalCreateWareHouseInput, setOpenModalCreateWareHouseInput] = useState(false)

    // let num = 1

    useEffect(() => {
        const getWareHouseById = async () => {
            try {
                let res = await GetWareHouseByIdApi(param.wareHouseId);
                // console.log(res.data)
                setHeaderData(res.data)
            } catch (error) {
                console.log("error traer los datos del almacen " + param.wareHouseId)
            }
        }

        getWareHouseById()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])

    useEffect(() => {
        setStateData("loading...")
        setItem({})
        const getData = async () => {
            try {
                let res = await GetHistoryWareHouseByIdApi(param.wareHouseId);
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        setStateData("")
                        setByQuery("")
                    }
                    else {
                        setStateData("sin registros...")
                    }
                    setDesc(false)
                    setCurrentPage(1)
                    setData(res.data)

                    // getGlobalData()
                }
            } catch (error) {
                setStateData("Error al cargar lista")
            }

        }
        getData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])


    useEffect(() => {
        setTotal(back.length)
        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
    }, [back, perPage])

    useEffect(() => {
        setCurrentPage(1)
    }, [perPage])


    useEffect(() => {
        // no replicar a = b por que se toma como si fuera el mismo
        const dataBackup = Array.from(data)
        setItems([
            ...dataBackup
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
                .splice((currentPage * perPage - perPage), perPage)
        ])
        setBack([
            ...data
                // .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery) || a.telefono.toLowerCase().includes(byQuery))
                .filter(a => a.full_data?.trim().toLowerCase().includes(byQuery))
        ])

        let div = back.length / perPage
        if (div - parseInt(div) === 0) {
            setTotalPages(div)
        }
        else {
            setTotalPages(parseInt(div) + 1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, currentPage, perPage])

    // const getGlobalData = async () => {
    //     try {
    //         let res = await GetGlobalDataApi();
    //         if (res.status === 200) {
    //             dispatch(getGlobalDataAction(res.data))
    //         }
    //     } catch (error) {
    //         console.log('error in reducers')
    //     }
    // }

    const onChangeInput = (e) => {
        onChangeInputData(
            setByQuery,
            e,
            data,
            currentPage,
            setCurrentPage,
            setItems,
            perPage,
            setBack,
            setStateData
        )
    }

    const handleCloseConfirmation = () => setModalConfirmation(false)
    // const handleShowConfirmation = () => {
    //     setModalConfirmation(true);
    // }

    const deleteData = async () => {
        try {
            setButtonDisabled(true)
            let res = await DeleteHistoryWareHouseByIdApi(item.id)
            if (res.status === 200) {
                handleCloseConfirmation()
                setLoad(!load)

                dispatch(toastToggleAction({
                    show: true,
                    bg: 'success',
                    title: 'Exito!',
                    message: 'Eliminado correctamente!',
                    variant: 'bold'
                }))
                setButtonDisabled(false)
            }
        } catch (error) {
            setButtonDisabled(false)
            // console.log(error.response)
            dispatch(toastToggleAction({
                show: true,
                bg: 'danger',
                title: 'Error!',
                message: error.response.data.message ? error.response.data.message : 'No se puede eliminar el cliente!',
                variant: 'bold'
            }))
        }
    }

    return (
        <GeneralContainer>
            <div className="row gx-3">
                <div className="card p-0">
                    <div className="card-body">
                        <div className="row d-flex justify-content-center gx-0">
                            <div className="col-12 col-sm-6 col-lg-5 mb-3">
                                <div className="h5">Información del Producto</div>
                                <div
                                    style={{
                                        fontSize: '13px'
                                    }}
                                >
                                    <span>Nombre: </span><span className="text-primary fw-bold">{headerData.p_nombre}</span><br />
                                    <span>Capacidad: </span><span className="text-primary fw-bold">{headerData.p_capacidad}</span><br />
                                    <span>Descripción: </span><span className="text-primary fw-bold">{headerData.p_descripcion}</span><br />
                                </div>
                            </div>
                            <div className="col-12 col-sm-4 mb-2">
                                <div className="h5">Disponibilidad</div>
                                <div
                                    style={{
                                        fontSize: '13px'
                                    }}
                                >
                                    <span>Estado: </span><span className="text-primary fw-bold">{headerData.estado}</span><br />
                                    <span>Cantidad actual: </span><span className={`text-${headerData.cantidad_actual === 0 ? 'danger' : 'success'} fw-bold`}>{headerData.cantidad_actual}</span>
                                    {
                                        headerData.cantidad_actual === 0 &&
                                        <span className='text-danger'>
                                            {' '}(almacén vacio)
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer p-1">
                        <div className=" row g-1 d-flex justify-content-end"
                            style={{
                                fontSize: '15px'
                            }}
                        >
                            {
                                headerData.estado === "TV" ?
                                    <div className="col-6 col-sm-4 col-md-2">
                                        <ButtonTextIcon
                                            icon={<i className='ion-plus-round' />}
                                            variant={'info'}
                                            title={'Vacios recogidos'}
                                            onClick={() => setOpenModalCreateWareHouseInput(true)}
                                        />
                                    </div>
                                    :
                                    <div className="col-6 col-sm-4 col-md-2">
                                        <ButtonTextIcon
                                            icon={<i className='ion-minus-round' />}
                                            variant={'info'}
                                            title={'Venta'}
                                            onClick={() => setOpenModalCreateWareHouseSale(true)}
                                        />
                                    </div>
                            }
                            {/* <div className="col-6 col-sm-4 col-md-2">
                                <ButtonTextIcon
                                    icon={<i className='ion-plus-round' />}
                                    variant={'danger'}
                                    title={'Ingresos'}
                                    onClick={() => setOpenModalCreateWareHouseHome(true)}
                                />
                            </div> */}
                            <div className="col-6 col-sm-4 col-md-2">
                                <ButtonTextIcon
                                    icon={<i className='ion-minus-round' />}
                                    variant={'warning'}
                                    title={'Egresos'}
                                    onClick={() => setOpenModalCreateWareHouse(true)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`d-${stateData === "loading..." ? 'flex' : 'none'}`}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            background: 'rgba(255,255,255,.8)',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <span className="loader"></span>
                    </div>
                </div>
                <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                    {
                        <>
                            <div className="card card-danger card-outline">
                                <div className="p-1">
                                    <Searcher
                                        onChangeInput={onChangeInput}
                                        byQuery={byQuery}
                                    />
                                </div>
                                <div className="p-1">
                                    <Paginator
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                        totalPages={totalPages}
                                        setPerPage={setPerPage}
                                        perPage={perPage}
                                        setLoad={setLoad}
                                        load={load}
                                        stateData={stateData}
                                        setFull={setFull}
                                        full={full}
                                    />
                                </div>
                                <div className=" p-1 table-warning rounded-top">
                                    <Info
                                        total={total}
                                        currentPage={currentPage}
                                        perPage={perPage}
                                        totalPages={totalPages}
                                    />
                                </div>
                                <div className="card-body p-1">
                                    <div className="content-basic">
                                        <Table>
                                            <thead>
                                                <Tr>
                                                    <ThParam
                                                        label='Doc'
                                                        onClick={() => order('codigo', data, desc, setData, setDesc)}
                                                        width='140px'
                                                    />
                                                    <ThParam
                                                        label='Fecha de registro'
                                                        onClick={() => order('created_at', data, desc, setData, setDesc)}
                                                        width='220px'
                                                    />
                                                    <ThParam
                                                        label='Tipo'
                                                        onClick={() => order('tipo', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    />
                                                    <ThParam
                                                        label='Encargado'
                                                        onClick={() => order('u_nombre', data, desc, setData, setDesc)}
                                                        width='120px'
                                                    />
                                                    <ThParam
                                                        label='Cantidad'
                                                        onClick={() => orderNum('cantidad', data, desc, setData, setDesc)}
                                                        width='85px'
                                                    />
                                                    <ThParam
                                                        label='Saldo'
                                                        onClick={() => orderNum('saldo', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    />
                                                    {/* <ThParam
                                                        label='Acciones'
                                                        // onClick={() => order('estado', data, desc, setData, setDesc)}
                                                        width='75px'
                                                    /> */}
                                                </Tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    stateData !== "" ?
                                                        <tr>
                                                            <td colSpan={5}>
                                                                {stateData === "loading..."
                                                                    ?
                                                                    <Loading />
                                                                    :
                                                                    <div
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            fontWeight: 'bold',
                                                                            color: 'rgba(0,0,0,.4)',
                                                                            padding: '5px'
                                                                        }}
                                                                    >
                                                                        {stateData}
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                        :
                                                        items?.map((data) => (
                                                            <Tr
                                                                key={data.id}
                                                                onClick={() => setItem(data)}
                                                                mark={item && item.id === data.id && true}
                                                            // color={data.tipo === 'Ingreso' ? 'lightseagreen' : 'firebrick'}
                                                            >
                                                                <Td
                                                                    width='140px'
                                                                >
                                                                    <div className="row">
                                                                        <div className="col d-flex"
                                                                            style={{
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <i className="bi bi-clipboard-data-fill d-flex p-1"
                                                                                style={{
                                                                                    fontSize: '18px'
                                                                                }}
                                                                            />
                                                                            <span>
                                                                                {data.codigo}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Td>
                                                                <Td
                                                                    width='220px'
                                                                >
                                                                    {data.created_at ? DateConvert(data.created_at) : ''}
                                                                </Td>
                                                                <Td
                                                                    width='120px'
                                                                    center
                                                                >
                                                                    {
                                                                        data.tipo === "Ingreso" ?
                                                                            <div className='badge-in-success'>
                                                                                {data.tipo}
                                                                            </div>
                                                                            :
                                                                            data.tipo === "Traspaso" ?
                                                                                <div className='badge-in-danger'>
                                                                                    {data.tipo}
                                                                                </div>
                                                                                :
                                                                                data.tipo === "Venta" ?
                                                                                    <div className='badge-in-secondary'>
                                                                                        {data.tipo}
                                                                                    </div>
                                                                                    :
                                                                                    <div className='badge-in-primary'>
                                                                                        {data.tipo}
                                                                                    </div>

                                                                    }
                                                                    {/* {' ' + num++} */}
                                                                </Td>
                                                                <Td
                                                                    width='120px'
                                                                >
                                                                    {
                                                                        data.ag_nombre ?
                                                                            data.ag_nombre
                                                                            :
                                                                            (
                                                                                data.user_id ?
                                                                                    <>
                                                                                        {data.u_nombre}{' '}
                                                                                        {data.u_apellido_paterno}{' '}
                                                                                        {data.u_apellido_materno}
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {data.c_nombre}{' '}
                                                                                        {data.c_apellido_paterno}{' '}
                                                                                        {data.c_apellido_materno}
                                                                                    </>
                                                                            )
                                                                    }
                                                                </Td>
                                                                <Td
                                                                    width='85px'
                                                                    center
                                                                    fontWeight='bold'
                                                                >
                                                                    <span
                                                                        className={`text-${data.tipo === 'Ingreso' ? 'success' : 'danger'}`}
                                                                    >
                                                                        {data.cantidad}
                                                                    </span>
                                                                </Td>
                                                                <Td
                                                                    width='75px'
                                                                    center
                                                                    fontWeight='bold'
                                                                >
                                                                    <span
                                                                        className={`text-${data.tipo === 'Ingreso' ? 'success' : 'danger'}`}
                                                                    >
                                                                        {data.saldo}
                                                                    </span>
                                                                </Td>
                                                                {/* <Td
                                                                    width='75px'
                                                                    center={true}
                                                                >
                                                                    {
                                                                        num++ === 1 &&
                                                                        <ButtonIcon
                                                                            icon={"bi bi-trash-fill"}
                                                                            variant="danger"
                                                                            title="eliminar"
                                                                            onClick={() => handleShowConfirmation(data.id)}
                                                                        />
                                                                    }
                                                                </Td> */}
                                                            </Tr>
                                                        ))
                                                }
                                            </tbody >
                                        </Table>
                                    </div>
                                </div>
                                {
                                    total > perPage && stateData !== "loading..." &&
                                    <>
                                        <div className=" p-1 table-warning rounded-top">
                                            <Info
                                                total={total}
                                                currentPage={currentPage}
                                                perPage={perPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                        <div className="p-1">
                                            <Paginator
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                                setPerPage={setPerPage}
                                                perPage={perPage}
                                                setLoad={setLoad}
                                                load={load}
                                                stateData={stateData}
                                                setFull={setFull}
                                                full={full}
                                                hiddenpaginator={true}
                                            />
                                        </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </div>
                {
                    item.id &&
                    <div className={`col-12 col-md-${full ? '12' : '6'}`}>
                        <div className="card">
                            <div className='card-header p-1'>
                                <div className='row'>
                                    <div className='col-8'>
                                        Detalles del movimiento
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <div className='p-0'>
                                            <i className='bi bi-x-lg btn py-0 px-1' onClick={() => setItem({})} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body p-0">
                                <Detail
                                    detail={[
                                        { label: 'Fecha de registro', value: DateConvert(item.created_at) },
                                        { label: 'Tipo', value: item.tipo },
                                        { label: 'Encargado', value: (item.u_nombre ? item.u_nombre : (item.ag_nombre ? item.ag_nombre : ((item.c_nombre ? item.c_nombre : '')) + ' ' + (item.c_apellido_paterno ? item.c_apellido_paterno : '') + ' ' + (item.c_apellido_materno ? item.c_apellido_materno : ''))) + ' ' + (item.u_apellido_paterno ? item.u_apellido_paterno : '') + ' ' + (item.u_apellido_materno ? item.u_apellido_materno : '') },
                                        { label: 'DOC', value: item.codigo },
                                        { label: 'Cantidad', value: item.cantidad },
                                        { label: 'Saldo', value: item.saldo },
                                        { label: 'Observaciones', value: item.obs },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* <ModalCreateHistorialWareHouse
                show={openModalCreateWareHouse}
                handleClose={() => setOpenModalCreateWareHouse(false)}
                setLoad={setLoad}
                load={load}
                wareHouse={headerData}
            /> */}
            {
                openModalCreateWareHouse &&
                <ModalAlmacenEgreso
                    show={openModalCreateWareHouse}
                    handleClose={() => setOpenModalCreateWareHouse(false)}
                    setLoad={setLoad}
                    load={load}
                    wareHouse={headerData}
                />
            }
            {/* <ModalCreateHistorialWareHouseHome
                show={openModalCreateWareHouseHome}
                handleClose={() => setOpenModalCreateWareHouseHome(false)}
                setLoad={setLoad}
                load={load}
                wareHouse={headerData}
            /> */}

            {
                openModalCreateWareHouseHome &&
                <ModalAlmacenIngreso
                    show={openModalCreateWareHouseHome}
                    handleClose={() => setOpenModalCreateWareHouseHome(false)}
                    setLoad={setLoad}
                    load={load}
                    wareHouse={headerData}
                />
            }

            <ModalCreateHistorialWareHouseSale
                show={openModalCreateWareHouseSale}
                handleClose={() => setOpenModalCreateWareHouseSale(false)}
                setLoad={setLoad}
                load={load}
                wareHouse={headerData}
            />
            <ModalCreateHistorialWareHouseInput
                show={openModalCreateWareHouseInput}
                handleClose={() => setOpenModalCreateWareHouseInput(false)}
                setLoad={setLoad}
                load={load}
                wareHouse={headerData}
            />
            <ModalConfirmation
                show={modalConfirmation}
                handleClose={handleCloseConfirmation}
                text='¿Seguro que desea eliminar este stock?'
                buttonText='Eliminar'
                variant='danger'
                onClick={() => deleteData()}
                buttonDisabled={buttonDisabled}
            />
        </GeneralContainer >
    )
}