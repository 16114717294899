import { Formik, Form } from 'formik'
import React, { useMemo, useRef, useState } from 'react'
import { Accordion, Button, Modal } from 'react-bootstrap'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../components/button/ButtonSubmitSave'
import Label from '../../../components/label/Label'
import { VenueLocationIcon } from '../../../components/maps/VenueIconLocation'
import { UpdateGeoClientApi } from '../../../http/Services/ClientApi'
import { toastToggleAction } from '../../../redux/action/toastAction'
import { recall } from '../../globalParams/GlobalDataFilter'


export default function ModalUpdateGeoClient({
    show,
    handleClose,
    load,
    setLoad,
    client
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()

    const center = {
        lat: client.geolocalizacion &&
            (client.geolocalizacion.split(',').length === 2) &&
            !isNaN(client.geolocalizacion.split(',')[0]) &&
            !isNaN(client.geolocalizacion.split(',')[1]) ? client.geolocalizacion.split(',')[0] : -16.524568684632587,
        lng: client.geolocalizacion &&
            (client.geolocalizacion.split(',').length === 2) &&
            !isNaN(client.geolocalizacion.split(',')[0]) &&
            !isNaN(client.geolocalizacion.split(',')[1]) ? client.geolocalizacion.split(',')[1] : -68.21297334607009,
    }
    // const centerDefault = center.lat + ', ' + center.lng
    const centerDefault = client.geolocalizacion ? client.geolocalizacion : ''

    const [position, setPosition] = useState(center)
    const [location, setLocation] = useState(centerDefault)
    // const [draggable, setDraggable] = useState(true)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosition(marker.getLatLng())
                    setLocation(marker._latlng.lat + ',' + marker._latlng.lng)
                }
            },
        }),
        [],
    )
    // const toggleDraggable = useCallback(() => {
    //     setDraggable((d) => !d)
    // }, [])

    const currentLocation = () => {
        navigator.geolocation.getCurrentPosition(function (position) {
            setLocation(position.coords.latitude + "," + position.coords.longitude)
            setPosition({
                lat: position.coords.latitude,
                lng: position.coords.longitude
            })
        });
    }

    const handleChangeOne = (e) => {
        setLocation(e.target.value)
    }

    return (
        <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='h6 fw-bold text-secondary'>Editar Geolocalización de Cliente</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={{
                    id: client.id,
                    geolocalizacion: client.geolocalizacion ? client.geolocalizacion : ''
                }}

                onSubmit={(valores, { resetForm }) => {
                    setButtonDisabled(true)
                    // console.log(valores)
                    const createClient = async () => {
                        try {
                            valores.geolocalizacion = location
                            let res = await UpdateGeoClientApi(valores)
                            if (res.status === 200) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'success',
                                    title: 'Exito!',
                                    message: 'Editado correctamente!',
                                    variant: 'bold'
                                }))
                                resetForm()
                                recall(setLoad, load)
                                handleClose()
                                setButtonDisabled(false)
                            }
                            else {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Alerta!',
                                    message: 'Los datos podrian no haberse cargado!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        } catch (error) {
                            dispatch(toastToggleAction({
                                show: true,
                                bg: 'danger',
                                title: 'Error!',
                                message: 'Error al editar ubicacion del cliente!',
                                variant: 'bold'
                            }))
                            setButtonDisabled(false)
                        }
                    }

                    createClient();
                }}
            >
                {({ values, handleChange }) => (
                    <React.Fragment>
                        <Form>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-9">
                                                <Label
                                                    title='Geolocalización'
                                                >
                                                    <input
                                                        id='geolocalizacion'
                                                        type="text"
                                                        className={`form-control ${location && location !== "" && 'bg-primary border border-primary'}`}
                                                        placeholder="Geolocalización"
                                                        onChange={handleChangeOne}
                                                        value={location}
                                                        disabled={buttonDisabled}
                                                        required
                                                    />
                                                </Label>
                                            </div>
                                            <div className="col-3 mt-4 text-right">
                                                <span className="input-group-text btn btn-primary" type='button' onClick={() => currentLocation()}><i className='bi bi-geo-alt-fill' /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header className='p-0 text-dark' >Editar ubicación manualmente</Accordion.Header>
                                                <Accordion.Body className='p-1 border border-secondary border-3'>
                                                    <MapContainer center={center} zoom={13} scrollWheelZoom={true}>
                                                        <TileLayer
                                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        />
                                                        <Marker
                                                            draggable={true}
                                                            eventHandlers={eventHandlers}
                                                            position={position}
                                                            ref={markerRef}
                                                            icon={VenueLocationIcon}
                                                        >
                                                            <Popup minWidth={60} >
                                                                <span
                                                                    style={{
                                                                        fontSize: '12px'
                                                                    }}
                                                                >
                                                                    {location}
                                                                </span>
                                                            </Popup>
                                                        </Marker>
                                                    </MapContainer>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Cancelar
                                </Button>
                                <ButtonSubmitSave
                                    text='Editar'
                                    // color='#df5312'
                                    disabled={buttonDisabled}
                                />
                                {/* <Button variant="primary" onClick={handleClose}>
                                    Cancelar
                                </Button> */}
                            </Modal.Footer>
                        </Form>
                    </React.Fragment>
                )}
            </Formik>
        </Modal>
    )
}
