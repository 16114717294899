import React from 'react'

export default function Info({
    total,
    currentPage,
    perPage,
    totalPages
}) {
    return (
        <div
            style={{
                fontWeight: '600',
                fontSize: '12.5px'
            }}
        >
            <span className='text-secondary'>Total registros: </span><span className='text-dark fw-bold'>{total}</span>{', '}
            <span className='text-secondary'>Cantidad por página: </span><span className='text-dark fw-bold'>{perPage}</span>{', '}
            <span className='text-secondary'>Total páginas: </span><span className='text-dark fw-bold'>{totalPages}</span>{', '}
            <span className='text-secondary'>Página actual: </span><span className='text-dark fw-bold'>{currentPage}</span>{' '}
        </div>
    )
}
