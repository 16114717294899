import React from 'react'
import CardBasic from '../../../components/card/CardBasic'
import CardBasicMin from '../../../components/card/CardBasicMin'
import CardButton from '../../../components/card/CardButton'

export default function CardSeller({ note, cardState, handleShowConfirmation }) {

    return (
        <div className="row gx-3">
            {/* {
                cardState !== 'button' && cardState !== 'show' &&
                <div className='col-12 text-center py-5'>
                    <div className="loader-lg">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            } */}
            {
                cardState === 'button' &&
                <div className="col-12 col-lg-6">
                    <CardButton
                        title='Aperturar cuaderno diario'
                        sup=''
                        description='Cuadernos diarios'
                        icon={<i className="ion ion-ios-bookmarks" />}
                        color='warning'
                        onClick={handleShowConfirmation}
                    />
                </div>
            }
            {
                cardState === 'show' &&
                <>
                    <div className="col-6">
                        <CardBasic
                            h3={note.codigo}
                            sup=''
                            description='Número de cuaderno'
                            icon={<i className="ion ion-ios-bookmarks" />}
                            // icon={<i className="ion ion-clipboard" />}
                            to={`notes/${note.id}`}
                            color='bg-warning'
                        />
                    </div>
                    <div className="col-6">
                        <CardBasic
                            h3={note.num_clientes ? note.num_clientes : 0}
                            sup=''
                            description='Clientes asignados'
                            icon={<i className="ion ion-person-stalker" />}
                            to={`clients`}
                            color='bg-danger'
                        />
                    </div>
                    <div className="col-12"></div>
                    {/* <div className="col-lg-3 col-6">
                        <CardBasic
                            h3='10'
                            sup='%'
                            description='Pedidos completados'
                            icon={<i className="ion ion-bag" />}
                            // icon={<i className="ion ion-stats-bars" />}
                            to='orders'
                            color='success'
                        />
                    </div> */}
                    {/* <div className="col-lg-3 col-4">
                        <CardBasic
                            h3={note.total_venta}
                            sup=''
                            description='Clientes registros hoy'
                            icon={<i className="ion ion-person-add" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div> */}
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_venta}
                            sup='Bs.'
                            description='Total venta'
                            icon={<i className="ion ion-android-cart" />}
                            // to='clients'
                            color='table-info'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_pagos_confirmar}
                            sup='Bs.'
                            description='Total venta (Cheque, Tranferencia)'
                            icon={<i className="ion ion-android-cart" />}
                            // to='clients'
                            color='table-secondary'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_cuaderno}
                            sup='Bs.'
                            description='Total venta (Efectivo)'
                            icon={<i className="ion ion-checkmark-circled" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_garantia}
                            sup='Bs.'
                            description='Total garantía'
                            icon={<i className="ion ion-soup-can" />}
                            // to='clients'
                            color='table-warning'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_cobranza}
                            sup='Bs.'
                            description='Total cobrado'
                            icon={<i className="ion ion-pie-graph" />}
                            // to='clients'
                            color='table-primary'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_deuda}
                            sup='Bs.'
                            description='Total deuda'
                            icon={<i className="ion ion-alert-circled" />}
                            // to='clients'
                            color='table-danger'
                        />
                    </div>
                    <div className="col-4">
                        <CardBasicMin
                            title={note.total_gasto}
                            sup='Bs.'
                            description='Total gastos'
                            icon={<i className="ion ion-medkit" />}
                            // to='clients'
                            color='table-secondary'
                        />
                    </div>
                    {/* <div className="col-4">
                        <CardBasicMin
                            title={note.total_turril_recogido}
                            sup=''
                            description='Total turriles recogidos'
                            icon={<i className="ion ion-soup-can" />}
                            // to='clients'
                            color='table-success'
                        />
                    </div> */}
                </>
            }
        </div>

    )
}
// https://ionic.io/ionicons/v2