import React from 'react'

export default function Searcher({ onChangeInput, byQuery }) {
    return (

        <div className="row px-0 d-flex justify-content-end">
            {/* <div className="col d-none d-sm-flex col-5 d-flex justify-content-end"> */}
            <div className="col-3 col-sm-3 d-flex justify-content-end p-0"
                style={{
                    verticalAlign: 'middle',
                    alignItems: 'center',
                }}
            >
                <small><b>Buscar:</b></small>
            </div>
            <div className="col-9 col-sm-6 col-md-5 col-lg-4 d-flex justify-content-end">
                <input
                    type="text"
                    className='form-control'
                    style={{
                        fontWeight: '600',
                        // border:'2px solid #8a8a8a'
                        boxShadow:'none'
                    }}
                    placeholder='buscar...'
                    onChange={onChangeInput}
                    value={byQuery}
                    // autoFocus
                />
            </div>
        </div>
    )
}
