import React from 'react'

export default function ButtonBar({
    text,
    disabled,
    color = '#dc3545',
    onClick,
    fs
}) {
    return (
        <button
            onClick={onClick}
            // style={{ background: '#00bfa5', color: 'white' }}
            style={{
                background: color, color: 'rgb(250,250,250)',
                fontSize: fs ? '14px' : '14px',
                
                padding:'7px 5px',
                width:'100%',
                fontWeight:'bold'
            }}
            className={`btn elevation-2 border border-none bg-${color}`}
            disabled={disabled}
        >
            {text}
        </button>
    )
}
