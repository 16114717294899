import axios from '../httpConfig'

export async function GetProfileApi() {
    const response = axios({
        url: `/user/profile`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetMyClientsApi() {
    const response = axios({
        url: `/user/clients`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetUserPermissionsApi(data) {
    const response = axios({
        url: `/user/${data}/permissions`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetUserSellersApi(page = 1, perPage = 100, forQuery = '', typeQuery = 'nombre', orderBy = 'id', orderByDesc = "DESC") {
    const response = axios({
        url: `/user/sellers?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetGlobalDataApi() {
    const response = axios({
        url: `/user/globalData`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetSellersApi() {
    const response = axios({
        url: `/user/sellers`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetPointsOfSaleApi() {
    const response = axios({
        url: `/user/points-of-sale`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetAllSellersApi() {
    const response = axios({
        url: `/user/allSellers`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}
