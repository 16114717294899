/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import CheckBoxBasic from '../../components/checkBox/CheckBoxBasic'
import InputTextBasic from '../../components/textField/InputTextBasic'
import ButtonSubmitBasic from '../../components/button/ButtonSubmitBasic'
import { Form, Formik } from 'formik'
import BoxAuth from '../../components/box/BoxAuth'
import { useDispatch } from 'react-redux'
import { loginAction } from '../../redux/action/authAction'
import { LoginApi } from '../../http/Services/AuthApi'
import { toastToggleAction } from '../../redux/action/toastAction'
import { toastIconToggleAction } from '../../redux/action/toastIconAction'
import config from '../../config.json'

export default function Login() {

    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [showPass, setShowPass] = useState(false)
    const handleShowPass = () => {
        setShowPass(!showPass)
    }

    return (
        <>
            <BoxAuth
                title={(config.name ? config.name: "Soya Sari")}
                subtitle='Introduce tus credenciales para poder iniciar sesión'
            >
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        // activo: false
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)

                        const login = async () => {
                            try {
                                let res = await LoginApi(valores);
                                if (res.status === 200) {
                                    dispatch(loginAction(res.data))
                                    dispatch(toastIconToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Eliminado correctamente!',
                                        variant: 'bold'
                                    }))
                                    // resetForm()

                                    setTimeout(() => {
                                        window.location = '/dashboard/home'
                                    }, 250);
                                }
                            } catch (error) {
                                setButtonDisabled(false)
                                // console.log(error)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'warning',
                                    title: 'Error!',
                                    message: error.response && error.response.data ? error.response.data.message : 'Usuario o contraseña incorrectos!',
                                    variant: 'bold'
                                }))
                            }
                        }

                        login()
                    }}
                >
                    {({ values, handleChange }) => (
                        <Form>
                            <InputTextBasic
                                id='email'
                                placeholder='Código'
                                name='email'
                                // icon={<ion-icon name="card"></ion-icon>}
                                icon={<i className='ion-card' />}
                                type='text'
                                onChange={handleChange}
                                value={values.email}
                                disabled={buttonDisabled}
                            />
                            <InputTextBasic
                                id='password'
                                placeholder='Contraseña'
                                name='password'
                                // icon={<ion-icon name="key"></ion-icon>}
                                icon={<i className="ion-locked" />}
                                type={showPass ? 'text' : 'password'}
                                onChange={handleChange}
                                value={values.password}
                                disabled={buttonDisabled}
                            />
                            <div className="row">
                                <CheckBoxBasic
                                    id='activo'
                                    name='activo'
                                    label='Mostrar contraseña'
                                    onChange={handleShowPass}
                                    value={showPass}
                                    disabled={buttonDisabled}
                                />
                                <div className='col-12'>
                                    <ButtonSubmitBasic
                                        text='Iniciar Sesión'
                                        disabled={buttonDisabled}
                                        color='bg-warning'
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
                {/* <div className='mb-0' style={{ textAlign: 'right', marginTop: '10px' }}>
                    <ButtonLink
                        to='/recovery-account'
                        text='¿Olvidaste tu contraseña?'
                    />
                    <br />
                </div> */}
            </BoxAuth>
        </>
    )
}
