import React from "react";
import { Th } from "../../components/table/Th";

export const ThParam = ({ label, onClick, width }) => {
  return (
    <Th
      onClick={onClick}
      width={width}
    >
      <div className='row p-0 m-0'
      >
        <div className="col-9 p-0 d-flex"
          style={{
            alignItems: 'center',
            fontSize:'14px',
            color: '#eee',
          }}

        >
          {label}
        </div>
        <div className="col-3 p-0 d-flex"
          style={{
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <div
            className="p-0 "
            style={{
              fontSize: '9px',
              textDecoration: 'none',
              color: '#929191',
              display: onClick ? '' : 'none'
            }}
          >
            <div className='bi bi-caret-up-fill d-flex' />
            <div className='bi bi-caret-down-fill d-flex' />
          </div>
        </div>
      </div>
    </Th>
  )
}
