import React from 'react'
import { LayerGroup, LayersControl, Marker, Popup, Tooltip } from 'react-leaflet'
import { VenueIconDanger } from './VenueIconDanger'
import { VenueIconWarning } from './VenueIconWarning'
import { VenueIconSuccess } from './VenueIconSuccess'

export default function Markers({ clients }) {
    // const locations = useSelector(state => state.locationClient.db)
    function openPopup(marker) {
        if (marker && marker.leafletElement) {
            window.setTimeout(() => {
                marker.leafletElement.openPopup()
            })
        }
    }
    return (
        <>
            <LayersControl position="topright">
                {
                    clients.map((user) => (
                        <LayersControl.Overlay checked={user.clients.length > 0 ? true: false} name={`${(user.nombre ? user.nombre : '') + ' ' + (user.apellido_paterno ? user.apellido_paterno : '') + ' ' + (user.apellido_materno ? user.apellido_materno : '')}`} key={user.id}>
                            <LayerGroup>
                                {
                                    user.clients?.map((client) => (
                                        client.geolocalizacion &&
                                        (client.geolocalizacion.split(',').length === 2) &&
                                        !isNaN(client.geolocalizacion.split(',')[0]) &&
                                        !isNaN(client.geolocalizacion.split(',')[1]) &&
                                        < Marker
                                            position={{ lat: client.geolocalizacion.split(',')[0], lng: client.geolocalizacion.split(',')[1] }}
                                            icon={
                                                client.estado === 'Nuevo' ?
                                                    VenueIconDanger
                                                    :
                                                    (client.order ?
                                                        VenueIconSuccess
                                                        :
                                                        VenueIconWarning
                                                    )

                                            }
                                            key={client.id}
                                            ref={openPopup}
                                        >
                                            {
                                                <>
                                                    {
                                                        client.estado === 'Nuevo' ?
                                                            <>
                                                                <Popup className='fw-bold' closeOnEscapeKey keepInView >
                                                                    <span className='text-danger'><i className='ion-person-add h6' /> nuevo cliente</span>
                                                                    <br />
                                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                                    <br />
                                                                    <small>{client.telefono}</small>
                                                                    <br />
                                                                    <small>{client.tipo_negocio}</small>
                                                                </Popup>
                                                                <Tooltip className='fw-bold border ' direction="top" offset={[0, -7]} opacity={1}>
                                                                    <span className='text-danger'><i className='ion-person-add h6' /> nuevo cliente</span>
                                                                    <br />
                                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                                </Tooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <Popup className='fw-bold' closeOnEscapeKey keepInView >
                                                                    <span
                                                                        style={{
                                                                            color: 'rgb(216, 188, 29)'
                                                                        }}
                                                                    ><i className='ion-person h6' /> cliente</span>
                                                                    <br />
                                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                                    <br />
                                                                    <small>{client.telefono}</small>
                                                                    <br />
                                                                    <small>{client.tipo_negocio}</small>
                                                                </Popup>
                                                                <Tooltip className='fw-bold border border-light' direction="top" offset={[0, -7]} opacity={1}>
                                                                    <span
                                                                        style={{
                                                                            color: 'rgb(216, 188, 29)'
                                                                        }}
                                                                    ><i className='ion-person h6' /> cliente</span>
                                                                    <br />
                                                                    {' ' + client.nombre + ' ' + (client.apellido_paterno ? client.apellido_paterno : '')}
                                                                </Tooltip>
                                                            </>
                                                    }
                                                </>
                                            }
                                        </Marker>
                                    ))
                                }
                            </LayerGroup>
                        </LayersControl.Overlay>
                    ))
                }
            </LayersControl>
        </>
    )
}