import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logoutAction } from '../../redux/action/authAction'
import ModalConfirmation from "../../components/modal/ModalConfirmation";
import { LogoutApi } from '../../http/Services/AuthApi';

export default function Header() {
    const [modalConfirmation, setModalConfirmation] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const handleCloseConfirmation = () => setModalConfirmation(false)
    const handleShowConfirmation = () => {
        setModalConfirmation(true);
    }
    const dispatch = useDispatch()
    const user = useSelector(state => state.auth)
    const globalData = useSelector(state => state.globalData)

    const style = {
        color: 'rgb(230,230,230)',
        fontSize: '18px',
        padding: '2px 6px',
        borderRadius: '50px'
    }

    const logout = () => {
        const logoutApi = async () => {
            setButtonDisabled(true)
            try {
                let res = await LogoutApi()
                if (res.status === 200) {
                    dispatch(logoutAction())
                    window.location.replace("/")
                }
            } catch (error) {
                dispatch(logoutAction())
                window.location.replace("/")
            }
        }
        logoutApi()
    }

    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-danger navbar-light sticky-top elevation-2" style={{ border: 'none' }}>
                <ul className="navbar-nav">
                    <li className="nav-item ml-2">
                        <span className="nav-link btn" data-widget="pushmenu"><i className="fas fa-bars" style={style} /></span>
                    </li>
                    <li className="nav-item ml-2">
                        <span className="nav-link text-white fw-bold btn" data-widget="pushmenu">
                            {user && user.departament.nombre === "Administración" && 'Administración'}
                            {user && user.departament.nombre === "Ventas" && 'Vendedor'}
                            {user && user.departament.nombre === "Clientes" && 'Cliente'}
                            {user && user.departament.nombre === "Punto de Venta" && 'Punto de venta'}
                        </span>
                    </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link className="nav-link btn" to="/dashboard/home" role="button">
                            <i className="bi bi-house-fill" style={style}></i>
                        </Link>
                    </li>
                    {
                        // window.localStorage.getItem('dp') === 'adm'
                        (window.sessionStorage.getItem('dp') === 'adm' || window.sessionStorage.getItem('dp') === 'vnt' || window.sessionStorage.getItem('dp') === 'pvnt')
                        &&
                        <li className="nav-item dropdown">
                            <span className={`nav-link btn ${globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts === 0 ? '' : 'bg-warning'}`} data-toggle="dropdown" >
                                <i className={`bi bi-bell-fill ${globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts === 0 ? '' : 'text-dark'}`} style={style}>
                                    {
                                        globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts > 0 &&
                                        <span className="position-absolute top-1 start-30 translate-middle p-2 bg-danger border border-light rounded-circle">
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    }
                                </i>
                                {/* <span className="position-absolute top-0 start-40 translate-middle badge rounded-pill bg-warning">
                                    99+
                                    <span className="visually-hidden">unread messages</span>
                                </span> */}
                                {/* <span className="badge badge-warning navbar-badge">15</span> */}
                            </span>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ width: '230px', fontSize: '14px' }}>
                                <span className="dropdown-header">{globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts > 0 ? (globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts === 1 ? '1 notificación' : ((globalData.orders + globalData.newClients + globalData.openNotes + globalData.accounts + globalData.barrelAccounts) + ' notificaciones')) : 'sin notificaciones'}</span>
                                {
                                    globalData.newClients > 0 &&
                                    <>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item fw-bold" to="/dashboard/clients">
                                            <i className="bi bi-people-fill mr-2" /><span className='text-danger'>{globalData.newClients}</span> {globalData.newClients > 1 ? 'nuevos clientes' : 'nuevo cliente'}
                                            {/* <span className="float-right text-muted text-sm">3 mins</span> */}
                                        </Link>
                                    </>
                                }
                                {
                                    globalData.accounts > 0 &&
                                    <>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item fw-bold" to={`/dashboard/${window.sessionStorage.getItem('dp') === 'adm' ? 'accounts' : 'debtors'}`}>
                                            <i className="bi bi-cash mr-2" /><span className='text-danger'>{globalData.accounts}</span> {globalData.accounts > 1 ? 'deudas pendientes' : 'deuda pendiente'}
                                            {/* <span className="float-right text-muted text-sm">3 mins</span> */}
                                        </Link>
                                    </>
                                }
                                {
                                    globalData.barrelAccounts > 0 &&
                                    <>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item fw-bold" to={`/dashboard/${window.sessionStorage.getItem('dp') === 'adm' ? 'barrel-accounts' : 'barrel-debtors'}`}>
                                            <i className="bi bi-archive-fill mr-2" /><span className='text-danger'>{globalData.barrelAccounts}</span> {globalData.barrelAccounts > 1 ? 'deudas de turriles' : 'deuda de turril'}
                                            {/* <span className="float-right text-muted text-sm">3 mins</span> */}
                                        </Link>
                                    </>
                                }
                                {
                                    globalData.openNotes > 0 &&
                                    <>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item fw-bold" to="/dashboard/notes">
                                            <i className="bi bi-journal-text mr-2" /><span className='text-danger'>{globalData.openNotes}</span> {globalData.openNotes > 1 ? 'Cuadernos abiertos' : 'Cuaderno abierto'}
                                            {/* <span className="float-right text-muted text-sm">3 mins</span> */}
                                        </Link>
                                    </>
                                }
                                {
                                    globalData.orders > 0 &&
                                    <>
                                        <div className="dropdown-divider" />
                                        <Link className="dropdown-item fw-bold" to="/dashboard/orders">
                                            <i className="bi bi-bag-fill mr-2" /><span className='text-danger'>{globalData.orders}</span> {globalData.orders > 1 ? 'nuevos pedidos' : 'nuevo pedido'}
                                            {/* <span className="float-right text-muted text-sm">3 mins</span> */}
                                        </Link>
                                    </>
                                }

                                {/* <div className="dropdown-divider" /> */}
                                {/* <span className="dropdown-item">
                                    <i className="fas fa-users mr-2" /> 1 pedido entregado
                                    <span className="float-right text-muted text-sm">12 hours</span>
                                </span> */}
                                {/* <div className="dropdown-divider" />
                                <span className="dropdown-item dropdown-footer">Ver todos los pedidos</span> */}
                            </div>
                        </li>
                    }
                    <li className="nav-item  mr-2">
                        <Link className="nav-link btn" onClick={() => handleShowConfirmation()}>
                            <i className="bi bi-door-open-fill" style={style}></i>
                        </Link>
                    </li>
                </ul>
            </nav>
            {
                modalConfirmation &&
                <ModalConfirmation
                    show={modalConfirmation}
                    handleClose={handleCloseConfirmation}
                    text='¿Desea salir?'
                    buttonText='Salir'
                    variant='danger'
                    buttonDisabled={buttonDisabled}
                    onClick={() => logout()}
                />
            }
        </>

    )
}
