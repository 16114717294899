import axios from '../httpConfig'

export async function LoginApi(data) {
    const response = axios({
        url: `/auth/login`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetUserByTokenApi() {
    const response = axios({
        url: `/auth/data`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function LogoutApi() {
    const response = axios({
        url: `/auth/logout`,
        method: 'put',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function ChangeUserPassApi(data) {
    const response = axios({
        url: `/auth/renewPass`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function ChangeMyPassApi(data) {
    const response = axios({
        url: `/auth/renewMyPass`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function ChangeClientPassApi(data) {
    const response = axios({
        url: `/auth/renewClientPass`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}