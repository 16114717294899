import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import { UpdateCashProductNoteApi } from '../../../../http/Services/NoteApi'
import { GetProductsApi } from '../../../../http/Services/ProductApi'

export default function ModalUpdateNoteCashProduct({ show, handleClose, recallAction, data }) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [products, setProducts] = useState([])

    const dispatch = useDispatch()

    useEffect(() => {
        const GetProducts = async () => {
            let res = await GetProductsApi()
            try {
                if (res.status === 200) {
                    setProducts(res.data)
                }
            } catch (error) {
                console.log("error products")
            }
        }

        GetProducts()
    }, [])


    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar efectivo</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        // user_id: user.value,
                        cuaderno_id: data.id,
                        moneda: 0,
                        billete: 0,
                        producto_id: '',
                        obs: ''
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        // console.log(valores)
                        // setButtonDisabled(true)
                        setButtonDisabled(true)
                        // setButtonSubmit(true)
                        // setButtonSubmit(false)
                        // confirmedAction()
                        const createHistoryNote = async () => {
                            try {
                                // console.log(valores)
                                let res = await UpdateCashProductNoteApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Modificado correctamente!',
                                        variant: 'bold'
                                    }))
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                    resetForm()
                                    // confirmedAction()
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                console.log(error)
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar venta!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                setButtonDisabled(false)
                            }
                        }

                        createHistoryNote();
                    }}
                >
                    {({ values, handleChange }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body className=''>
                                    <div className="row gx-2">
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Monedas'
                                            >
                                                <select
                                                    className="form-select fw-bold"
                                                    name="producto_id"
                                                    value={values.producto_id}
                                                    onChange={handleChange}
                                                    disabled={buttonDisabled}
                                                    required
                                                >
                                                    <option value='' label='...' required />
                                                    {
                                                        products.map(product => (
                                                            product.retornable !== "1" && product.retornable !== 1 &&
                                                            <option value={product.id} label={product.nombre + ', Bs. ' + product.precio} key={product.id} />
                                                        ))
                                                    }
                                                </select>
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Observaciones'
                                            >
                                                <InputBeforeIcon
                                                    rows={2}
                                                    onChange={handleChange}
                                                    placeholder='Observaciones...'
                                                    value={values.obs}
                                                    name='obs'
                                                    disabled={buttonDisabled}
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Monedas'
                                            >
                                                <InputBeforeIcon
                                                    id='moneda'
                                                    placeholder='moneda'
                                                    name='moneda'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.moneda}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        <div className="col-6 mb-2">
                                            <Label
                                                title='Billetes'
                                            >
                                                <InputBeforeIcon
                                                    id='billete'
                                                    placeholder='billete'
                                                    name='billete'
                                                    type='number'
                                                    onChange={handleChange}
                                                    value={values.billete}
                                                    disabled={buttonDisabled}
                                                    required={true}
                                                    min={0}
                                                    step={0.1}
                                                    param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Registrar efectivo'
                                        color='#00bfa5'
                                        disabled={buttonDisabled}
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
