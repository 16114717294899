import axios from '../httpConfig'

export async function CreateWareHouseApi(data) {
    const response = axios({
        url: `/wareHouse`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetWareHousesApi() {
    const response = axios({
        url: `/wareHouse`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetWareHouseByIdApi(id) {
    const response = axios({
        url: `/wareHouse/${id}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetHistoryWareHouseByIdApi(id) {
    const response = axios({
        url: `/wareHouse/${id}/historial`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function DeleteWareHouseByIdApi(id) {
    const response = axios({
        url: `/wareHouse/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetWareHousesWithUserApi(id, filter) {
    const response = axios({
        url: `/wareHouse/${id}/filter/${filter}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetWareHousesWithUserNotNullApi() {
    const response = axios({
        url: `/wareHouse/user/list`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}
