import axios from "axios";

export async function CreateHistoryNoteApi(data) {
    const response = axios({
        url: `/historyNote`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function DeleteHistoryNoteApi(id) {
    const response = axios({
        url: `/historyNote/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}