import { Formik, Form } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import ButtonSubmitSave from '../../../../components/button/ButtonSubmitSave'
import { toastToggleAction } from '../../../../redux/action/toastAction'
import InputBeforeIcon from '../../../../components/form/InputBeforeIcon'
import Label from '../../../../components/label/Label'
import SelecterDataButton from '../../../../components/form/SelecterDataButton'
import { CreateHistoryBarrelAccountApi } from '../../../../http/Services/HistoryBarrelAccountApi'
import { GetMyClientsWithOpenBarrelAccountApi } from '../../../../http/Services/BarrelAccountApi'
import ReactSelect from 'react-select'
import { DateConvert } from '../../../../utils/dateConvert'
import ButtonLinkIcon from '../../../../components/button/ButtonLinkIcon'

export default function ModalCreateHistoryBarrelAccount({
    show,
    handleClose,
    recallAction,
    cuadernoId
}) {
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const dispatch = useDispatch()
    const [clientBarrelAccount, setClientBarrelAccount] = useState(null)

    const [userOptions, setUserOptions] = useState([])
    const [clients, setClients] = useState(null)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const getClients = async () => {
            try {
                let res = await GetMyClientsWithOpenBarrelAccountApi();
                let resList = []
                if (res.status === 200) {
                    setClients(res.data)
                    res.data.map(data =>
                        resList.push({ value: data.id, label: (data.metodo_vacio + ' de ' + (data.metodo_vacio !== "prestamo" ? (data.saldo_garantia + " Bs. por " + data.saldo_cantidad + " T, ") : (data.saldo_cantidad + " T, ")) + (data.nombre + ' ' + (data.apellido_paterno ? data.apellido_paterno : '') + ' ' + (data.apellido_materno ? data.apellido_materno : '')) + ', apertura: ' + (DateConvert(data.created_at, 'L'))) })
                    )
                    setUserOptions(resList)
                }
            } catch (error) {

            }
        }
        getClients()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange2 = (e) => {
        console.log(e)
        setUser(e)
        if (e !== null) {
            setClientBarrelAccount(clients.find(cli => cli.id === e.value))
        }
        else {
            setClientBarrelAccount(null)
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} className='elevation-1' size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='h6 fw-bold text-secondary'>Registrar turril recogido</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={{
                        cuaderno_id: cuadernoId,
                        cantidad_turril: 0,
                        cuenta_turril_id: 0
                    }}

                    onSubmit={(valores, { resetForm }) => {
                        setButtonDisabled(true)
                        const CreateExpenseNote = async () => {
                            try {
                                valores.cuenta_turril_id = clientBarrelAccount.id
                                let res = await CreateHistoryBarrelAccountApi(valores)
                                if (res.status === 200) {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'success',
                                        title: 'Exito!',
                                        message: 'Creado correctamente!',
                                        variant: 'bold'
                                    }))
                                    resetForm()
                                    recallAction()
                                    handleClose()
                                    setButtonDisabled(false)
                                }
                                else {
                                    dispatch(toastToggleAction({
                                        show: true,
                                        bg: 'warning',
                                        title: 'Alerta!',
                                        message: 'Los datos podrian no haberse cargado!',
                                        variant: 'bold'
                                    }))
                                    // setButtonDisabled(false)
                                }
                            } catch (error) {
                                dispatch(toastToggleAction({
                                    show: true,
                                    bg: 'danger',
                                    title: 'Error al registrar pago!',
                                    message: 'Llene los datos correctamente!',
                                    variant: 'bold'
                                }))
                                // setButtonDisabled(false)
                            }
                        }

                        CreateExpenseNote();
                    }}
                >
                    {({ values, handleChange }) => (
                        <React.Fragment>
                            <Form>
                                <Modal.Body>
                                    <div className="row  d-flex justify-content-center">
                                        <div className="col-sm-8 mb-2">
                                            <Label
                                                title='Cliente'
                                            >
                                                <ReactSelect
                                                    maxMenuHeight={250}
                                                    options={
                                                        userOptions
                                                    }
                                                    name="user_id"
                                                    value={user}
                                                    onChange={handleChange2}
                                                    isDisabled={buttonDisabled}
                                                    required={true}
                                                    isClearable={true}
                                                    className='fw-bold'
                                                />
                                            </Label>
                                            <div>
                                                {
                                                    user !== null &&
                                                    <SelecterDataButton
                                                        data={user.label} />
                                                }
                                            </div>
                                        </div>
                                        <div className="col-10 col-lg-4 mb-2">
                                            <Label
                                                title='Cantidad de turril'
                                            >
                                                <InputBeforeIcon
                                                    id='cantidad_turril'
                                                    placeholder="cantidad_turril"
                                                    type='number'
                                                    onChange={handleChange}
                                                    name='cantidad_turril'
                                                    value={values.cantidad_turril}
                                                    disabled={buttonDisabled}
                                                    min={1}
                                                    max={clientBarrelAccount && clientBarrelAccount.saldo_cantidad ? clientBarrelAccount.saldo_cantidad : 1}
                                                // param='Bs.'
                                                />
                                            </Label>
                                        </div>
                                        <div className=" col-12 mt-3">
                                            <div className="row d-flex justify-content-center">
                                                <div className="col-12 col-lg-6 row d-flex justify-content-center">
                                                    <div className="row table-success py-1">
                                                        <div className="col-6"><small>Total cobrado</small></div>
                                                        <div className="col-3  text-success">
                                                            <strong>{clientBarrelAccount && clientBarrelAccount.metodo_vacio}</strong>
                                                        </div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                    <div className="row table-danger py-1">
                                                        <div className="col-6"><small>Total deuda turriles</small></div>
                                                        <div className="col-3  text-danger">
                                                            {<strong>{clientBarrelAccount && clientBarrelAccount.saldo_cantidad}</strong>}
                                                        </div>
                                                        <div className="col-3"></div>
                                                    </div>
                                                    <div className="row table-warning py-1">
                                                        <div className="col-6"><small>Turriles devueltos</small></div>
                                                        <div className="col-3  text-secondary">
                                                            <strong>{values.cantidad_turril}</strong>
                                                        </div>
                                                        <div className="col-3"><small>{values.metodo_vacio}</small></div>
                                                    </div>
                                                    {
                                                        clientBarrelAccount && clientBarrelAccount.metodo_vacio === "garantia" &&
                                                        <div className="row table-primary py-1">
                                                            <div className="col-6"><small>Total garantia</small></div>
                                                            <div className="col-3  text-primary">
                                                                <strong>{clientBarrelAccount.saldo_garantia} </strong>
                                                            </div>
                                                            <div className="col-3"><small>Bs.</small></div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 mt-2">
                                            <ButtonLinkIcon
                                                title={!clientBarrelAccount && 'Debe seleccionar un cliente!'}
                                                iconB={!clientBarrelAccount && <i className="bi bi-exclamation-triangle-fill" />}
                                                bg={!clientBarrelAccount && 'warning'}
                                                // color={!client && 'secondary'}
                                                // bold={false}
                                                btn={false}
                                            />
                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Cancelar
                                    </Button>
                                    <ButtonSubmitSave
                                        text='Recoger turril'
                                        color='#00bfa5'
                                        disabled={
                                            clientBarrelAccount
                                                &&
                                                !buttonDisabled
                                                ?
                                                (clientBarrelAccount.metodo_vacio === "garantia"
                                                    ?
                                                    (clientBarrelAccount.saldo_cantidad - values.cantidad_turril <= 0
                                                        ?
                                                        false
                                                        :
                                                        true
                                                    )
                                                    :
                                                    false
                                                )
                                                :
                                                true
                                        }
                                    />
                                </Modal.Footer>
                            </Form>
                        </React.Fragment>
                    )}
                </Formik>
            </Modal>
        </>
    )
}
