import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loading({ center = false }) {
    return (
        <div className="p-4 pl-2 text-md-center d-flex"
            style={{
                fontSize: '14px',
                fontWeight: 'bold',
                // color: 'rgba(0,0,0,.4)'
                alignItems: 'center',
                verticalAlign: 'middle',
                margin: center ? 'auto' : ''
            }}
        >
            {/* {stateData} */}
            <Spinner animation="border" variant="primary" /><span className='ml-2 text-secondary'>cargando...</span>
        </div>
    )
}
