import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Avatar from '../../../assets/profile.png'
import { useParams } from 'react-router-dom'
import { GetClientByIdApi } from '../../../http/Services/ClientApi'
import MapView from "../../../components/maps/MapView";
import { VerificationImageExist } from '../../../utils/VerificationImage'
import ClientImageModalCreate from './ClientImageModalCreate'
import { Tab, Tabs } from 'react-bootstrap'
import { Tr } from '../../../components/table/Tr'
import { ThParam } from '../../globalParams/ThParam'
import { orderNum } from '../../globalParams/GlobalDataFilter'
import Loading from '../../globalParams/Loading'
import { Td } from '../../../components/table/Td'
import { DateConvert } from '../../../utils/dateConvert'
import { TableMin } from '../../../components/table/TableMin'


export default function ClientProfile() {
    const auth = useSelector(state => state.auth)
    const [user, setUser] = useState({})
    const [open, setOpen] = useState(false)
    const [reset, setReset] = useState(false)
    const [stateData, setStateData] = useState("loading...")
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const [desc, setDesc] = useState(false)

    const param = useParams()

    useEffect(() => {
        if (auth.id !== 0) {
            const getClientByIdApi = async () => {
                try {
                    const res = await GetClientByIdApi(param.clientId)
                    setUser(res.data)
                    setData(res.data.accounts)
                    setData2(res.data.barrel_accounts)
                    if (res.data.accounts.length > 0) {
                        setStateData("")
                    }
                    else {
                        setStateData("sin registros...")
                    }

                } catch (error) {
                    console.log("error profile")
                }
            }
            getClientByIdApi()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, reset])

    const openModalImageCreate = () => {
        setOpen(!open)
    }

    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-danger card-outline">

                                <div className="card-body box-profile">
                                    <div className="text-center">
                                        <img className="" src={user && user.avatar ? require(`../../../assets/${VerificationImageExist(user.avatar)}`) : Avatar} alt="img" style={{ width: '150px', height: '150px' }} />
                                    </div>
                                    <h3 className="profile-username text-center">{user.nombre || '---'} {user.apellido_paterno || '---'}</h3>
                                    <p className="text-muted text-center">{user.tipo_negocio && (user.tipo_negocio === "Fabrica" ? "Fábrica" : user.tipo_negocio)}</p>
                                    <ul className="list-group list-group-unbordered mb-3 p-1">
                                        <li className="list-group-item">
                                            <i className="ion-ios-telephone"></i>
                                            <b> Teléfono</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.telefono || '--'}</span>
                                        </li>
                                        <li className="list-group-item">
                                            <i className="ion-locked"></i>
                                            <b> Código</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.codigo || '--'}</span>
                                        </li>
                                        {
                                            user.estado === "Activo" ?
                                                <li className="list-group-item  text-success">
                                                    <i className="ion-checkmark"></i>
                                                    <b> Estado</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.estado || '--'}</span>
                                                </li>
                                                :
                                                <li className="list-group-item">
                                                    <i className="ion-alert-circled"></i>
                                                    <b> Estado</b> <span className="float-right" style={{ textDecoration: 'none' }}>{user.estado || '--'}</span>
                                                </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="card card-warning card-outline">
                                <div className="card-header">
                                    Datos del cliente
                                </div>
                                <div className="card-body p-2 m-0">
                                    {/* <small><strong> Codigo de cliente</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.codigo || '--'}</small></p> */}
                                    <small><strong> Nombre</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.nombre || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Apellido paterno</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.apellido_paterno || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Apellido materno</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.apellido_materno || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Carnet</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.carnet || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Telefono</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.telefono || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Zona</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.zona || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Observaciones</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.obs || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Tiempo de consumo por turril</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{user.tiempo_consumo_turril || '--'}</small></p>
                                    <hr className='my-1' />
                                    <small><strong> Vendedor encargado</strong></small>
                                    <p className="text-muted p-0 m-0"><small>{(user.u_nombre + ' ' + (user.u_apellido_paterno ? user.u_apellido_paterno : "") + ' ' + (user.u_apellido_materno ? user.u_apellido_materno : "")) || '--'}</small></p>
                                </div>
                            </div>
                        </div>
                        <div className="col col-md-6">
                            <div className="card">
                                <div className="card-body p-1">
                                    <Tabs
                                        defaultActiveKey="deudas"
                                        className="fw-bold mb-1"
                                        style={{
                                            borderTopRightRadius:'7px',
                                            borderTopLeftRadius:'7px',
                                            background:'rgba(0,0,0,.15)'
                                        }}
                                        fill
                                    >
                                        <Tab eventKey="deudas" title='Deudas pendientes'>
                                            <div className="content-basic">
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label='Cuaderno'
                                                                onClick={() => orderNum('cua_id', data, desc, setData, setDesc)}
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Saldo'
                                                                onClick={() => orderNum('saldo', data, desc, setData, setDesc)}
                                                                width='60px'
                                                            />
                                                            <ThParam
                                                                label='Fecha límite'
                                                                onClick={() => orderNum('id', data, desc, setData, setDesc)}
                                                                width='100px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            stateData !== "" ?
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        {stateData === "loading..."
                                                                            ?
                                                                            <Loading />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    fontSize: '14px',
                                                                                    fontWeight: 'bold',
                                                                                    color: 'rgba(0,0,0,.4)',
                                                                                    padding: '5px'
                                                                                }}
                                                                            >
                                                                                {stateData}
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                :
                                                                user.accounts.map((data) => (
                                                                    <Tr
                                                                        key={data.id}
                                                                    >
                                                                        <Td
                                                                            width='80px'
                                                                            center
                                                                        >
                                                                            {data.codigo}
                                                                        </Td>
                                                                        <Td
                                                                            width='60px'
                                                                            center
                                                                        >
                                                                            <b>{data.saldo} <small>Bs</small></b>
                                                                        </Td>
                                                                        <Td
                                                                            width='100px'
                                                                        >
                                                                            {data.fecha_limite ? DateConvert(data.fecha_limite) : ''}
                                                                        </Td>
                                                                    </Tr>
                                                                ))
                                                        }
                                                    </tbody >
                                                </TableMin >
                                            </div>
                                        </Tab>
                                        <Tab eventKey="deudaTurriles" title="Deuda turriles">
                                            <div className="content-basic">
                                                <TableMin>
                                                    <thead>
                                                        <Tr>
                                                            <ThParam
                                                                label='Cuaderno'
                                                                onClick={() => orderNum('cua_id', data2, desc, setData2, setDesc)}
                                                                width='80px'
                                                            />
                                                            <ThParam
                                                                label='Saldo'
                                                                onClick={() => orderNum('saldo_cantidad', data2, desc, setData2, setDesc)}
                                                                width='60px'
                                                            />
                                                            <ThParam
                                                                label='Tipo'
                                                                onClick={() => orderNum('metodo_vacio', data2, desc, setData2, setDesc)}
                                                                width='75px'
                                                            />
                                                            <ThParam
                                                                label='Fecha'
                                                                onClick={() => orderNum('id', data2, desc, setData2, setDesc)}
                                                                width='100px'
                                                            />
                                                        </Tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data2.length <= 0 ?
                                                                <tr>
                                                                    <td colSpan={5}>
                                                                        <div
                                                                            style={{
                                                                                fontSize: '14px',
                                                                                fontWeight: 'bold',
                                                                                color: 'rgba(0,0,0,.4)',
                                                                                padding: '5px'
                                                                            }}
                                                                        >
                                                                            sin registros...
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                :
                                                                data2.map((subData) => (
                                                                    <Tr
                                                                        key={subData.id}
                                                                    >
                                                                        <Td
                                                                            width='80px'
                                                                            center
                                                                        >
                                                                            {subData.codigo}
                                                                        </Td>
                                                                        <Td
                                                                            width='60px'
                                                                            center
                                                                        >
                                                                            <b>{subData.saldo_cantidad}</b>
                                                                        </Td>
                                                                        <Td
                                                                            width='75px'
                                                                        >
                                                                            {subData.metodo_vacio}
                                                                        </Td>
                                                                        <Td
                                                                            width='100px'
                                                                        >
                                                                            {subData.created_at ? DateConvert(subData.created_at) : ''}
                                                                        </Td>
                                                                    </Tr>
                                                                ))
                                                        }
                                                    </tbody >
                                                </TableMin >
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                            <div className="card card-info card-outline">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-10">Foto del negocio </div>
                                        <div className="col-2 text-right">
                                            <i
                                                className='bi bi-image-fill btn p-0'
                                                // className='ion-ios-camera-outline btn py-0'
                                                style={{ fontSize: '17px' }}
                                                onClick={() => openModalImageCreate()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-2 m-0 d-flex justify-content-center">
                                    {
                                        (user.foto_negocio)
                                        &&
                                        <img src={user.foto_negocio} className="img-fluid" alt="..." />
                                    }
                                    {
                                        (!user.foto_negocio || user.foto_negocio === "")
                                        &&
                                        <img src={'https://via.placeholder.com/450'} className="img-fluid" alt="..." />
                                    }
                                </div>
                            </div>
                            {/* </div>
                                <div className="col-6"> */}
                            <div className="card card-success card-outline">
                                <div className="card-header">
                                    Geolocalización
                                </div>
                                <div className="card-body p-2 m-0">
                                    {
                                        user.geolocalizacion ?
                                            <MapView
                                                center={user.geolocalizacion}
                                                user={user}
                                                scroll={false}
                                            />
                                            :
                                            <em><small>Ubicación no registrada...</small></em>
                                    }
                                </div>
                            </div>
                            {/* </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <ClientImageModalCreate
                id={user.id}
                open={open}
                openModalImageCreate={openModalImageCreate}
                setReset={setReset}
                resett={reset}
            />
        </div>
    )
}
// https://tanstack.com/table/v8/docs/examples/react/pagination?from=reactTableV7&original=https://react-table-v7.tanstack.com/docs/examples/pagination