import React from 'react'
import config from '../../config.json'
// import Avatar from "./../../assets/profile.png";

export default function Preload() {
    return (
        <div className="preloader flex-column justify-content-center align-items-center">
            {/* <img className="animation__shake" src={Avatar} alt="AdminLTELogo" height={60} width={60} /> */}
            <h2 className="animation__shake" style={{ fontFamily: 'Patrick Hand', fontSize: '40px', color: '#dc3545', fontWeight: 'bold', textShadow:'3px 3px 6px rgb(0,0,0,.1)' }}>
                {config.name ? config.name : 'Soya Sari'}
            </h2>
        </div>
    )
}
