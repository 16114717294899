import axios from '../httpConfig'

export async function CreateWareHouseHistorialApi(data) {
    const response = axios({
        url: `/historyWarehouse`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}


export async function DeleteHistoryWareHouseByIdApi(id) {
    const response = axios({
        url: `/historyWarehouse/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}