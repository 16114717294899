import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import Markers from './Markers'
import config from '../../../../config.json'

export default function MapView({ center, user, scroll = true, clients }) {

    return (
        <MapContainer
            center={
                center &&
                    (center.split(',').length === 2) &&
                    !isNaN(center.split(',')[0]) &&
                    !isNaN(center.split(',')[1])
                    ?
                    { lat: center.split(',')[0], lng: center.split(',')[1] }
                    // { lat: "-16.544839", lng: "-68.185348" }
                    :
                    // { lat: "-16.524568684632587", lng: "-68.21297334607009" }
                    { lat: config.lat, lng: config.long }
            }
            zoom={13}
            scrollWheelZoom={scroll}
        >
            <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            />
                    <Markers clients={clients}/>
        </MapContainer>
    )
}
