import React from 'react'
import { Pagination } from 'react-bootstrap'
import Select from '../../../components/form/Select'
import { recall } from '../GlobalDataFilter'

export default function Paginator({
    currentPage,
    setCurrentPage,
    totalPages,
    setPerPage,
    perPage,
    setLoad,
    load,
    stateData,
    setFull,
    full,
    hiddenpaginator
}) {
    const handleChange = (e) => {
        setPerPage(e.target.value)
    }
    return (
        <div className="row px-0">
            {
                !hiddenpaginator ?
                    <>
                        <div className="col col-5 d-flex justify-content-start">
                            <Select
                                value={perPage}
                                title={'cantidad de registros por página'}
                                onChange={handleChange}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </Select>
                            <button
                                className="ml-1 btn py-0 bg-danger rounded px-3"
                                onClick={() => recall(setLoad, load)}
                                style={{
                                    alignItems: 'center',
                                    verticalAlign: 'middle'
                                }}
                                disabled={stateData === "loading..." ? true : false}
                            >
                                <i className="ion-loop h5" />
                            </button>
                            <button
                                className="ml-1 btn d-none d-md-block py-0 bg-secondary rounded px-3"
                                onClick={() => setFull(!full)}
                                style={{
                                    alignItems: 'center',
                                    verticalAlign: 'middle'
                                }}
                                disabled={stateData === "loading..." ? true : false}
                            >
                                <i className="ion-arrow-resize h5" />
                            </button>
                        </div>
                        <div className="col col-7 d-flex justify-content-end">
                            <Pagination size='md' className='p-0 m-0 fw-bold'>
                                <Pagination.First style={{ fontSize: '40px' }} onClick={() => setCurrentPage(1)} disabled={currentPage <= 1 && true} />
                                <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1 && true} />
                                <Pagination.Item active>{currentPage}</Pagination.Item>
                                <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={totalPages <= currentPage && true} />
                                <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={totalPages <= currentPage && true} />
                            </Pagination>
                        </div>
                    </>
                    :
                    <div className="col col-12 d-flex justify-content-end">
                        <Pagination size='md' className='p-0 m-0 fw-bold'>
                            <Pagination.First style={{ fontSize: '40px' }} onClick={() => setCurrentPage(1)} disabled={currentPage <= 1 && true} />
                            <Pagination.Prev onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage <= 1 && true} />
                            <Pagination.Item active>{currentPage}</Pagination.Item>
                            <Pagination.Next onClick={() => setCurrentPage(currentPage + 1)} disabled={totalPages <= currentPage && true} />
                            <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={totalPages <= currentPage && true} />
                        </Pagination>
                    </div>
            }
        </div>
    )
}
