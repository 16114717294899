import axios from '../httpConfig'

export async function GetMyClientsWithOpenBarrelAccountApi(page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/barrelAccount/me?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetClientBarrelAccountsApi(page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/barrelAccount?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}