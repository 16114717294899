import axios from "axios";

export async function GetMyOrdersApi() {
    const response = axios({
        url: `/order/personal`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function CreateOrderApi(data) {
    const response = axios({
        url: `/order`,
        method: 'post',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function DeleteOrderApi(id) {
    const response = axios({
        url: `/order/${id}`,
        method: 'delete',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function GetOrdersApi(page, perPage, forQuery, typeQuery, orderBy, orderByDesc = "DESC") {
    const response = axios({
        url: `/order?current_page=${page}&per_page=${perPage}&typeQuery=${typeQuery}&forQuery=${forQuery}&orderBy=${orderBy}&order=${orderByDesc}`,
        method: 'get',
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}

export async function UpdateStateOrderApi(data) {
    const response = axios({
        url: `/order/state`,
        method: 'put',
        data: data,
        headers: {
            'Content-Type': 'application/json-patch+json',
        }
    });
    return response
}