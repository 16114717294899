import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GetUserByTokenApi } from '../../http/Services/AuthApi'
import { GetGlobalDataApi } from '../../http/Services/UserApi'
import { dpAction, getDataAction, logoutAction } from '../../redux/action/authAction'
import { getGlobalDataAction } from '../../redux/action/globalDataAction'

export default function Initial() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        // if (localStorage.getItem('token')) {
        if (sessionStorage.getItem('token')) {
            const getUserByToken = async () => {
                try {
                    let res = await GetUserByTokenApi();
                    if (res.status === 200) {
                        dispatch(getDataAction(res))
                        dispatch(dpAction(res.data.departament.nombre))
                        // window.sessionStorage.setItem("dp",)
                    }
                } catch (error) {
                    // navigate('/')
                    dispatch(logoutAction())
                    window.location = '/'
                }
            }
            getUserByToken()
        }
        else {
            navigate('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // if (localStorage.getItem('token')) {
        if (sessionStorage.getItem('token')) {
            const getUserByToken = async () => {
                try {

                    let res = await GetGlobalDataApi();
                    if (res.status === 200) {
                        dispatch(getGlobalDataAction(res.data))
                    }
                } catch (error) {
                    dispatch(logoutAction())
                    window.location = '/'
                }
            }
            getUserByToken()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
        </>
    )
}
