import React from 'react'

export default function SelecterDataButton({
    data
}) {
    return (
        <div
            style={{ textAlign: 'center', padding:'5px' }} className='table-info'>
            <em style={{ fontSize: '13px', fontWeight: 'bold', display: 'inline-block' }} >
                {data}
            </em>
        </div>
    )
}
